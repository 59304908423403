import React, { useState, useEffect } from "react"
import styled from "styled-components"

import { useInView } from "react-intersection-observer"

import {
  firstInStyles,
  secondInStyles,
  thirdInStyles,
  fourthInStyles,
  bubbleZoomIn,
} from "./animations"

const Container = styled.div`
  // border: 1px solid hotpink;
  width: 100%;
  height: 100%;
  height: 80vh;

  position: relative;

  // margin-top: 15vh;
  // padding-top: 15vh;
  margin-bottom: 15vh;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media only screen and (max-width: 1194px) {
    height: 60vh;
  }

  @media only screen and (max-width: 640px) {
    height: 90vh;
    margin-bottom: 0;

    justify-content: flex-start;
    padding-top: 10vh;
  }

  @media only screen and (max-width: 375px) {
    height: 100vh;
  }

  &.animate {
    h2 {
      ${firstInStyles}
    }

    p {
      ${secondInStyles}
    }

    .value-heading {
      ${thirdInStyles}
    }

    .value-desc-container {
      ${fourthInStyles}
    }
  }
`

const HeadingContainer = styled.div`
  // border: 1px solid hotpink;
  width: 23%;
  height: auto;

  text-align: center;

  display: flex;
  flex-direction: column;
  align-items: center;

  @media only screen and (max-width: 1194px) {
    width: 30%;
  }

  @media only screen and (max-width: 834px) {
    width: 40%;
  }

  @media only screen and (max-width: 640px) {
    width: 90%;

    margin-bottom: 3rem;
  }
`

const Heading = styled.h2`
  // border: 1px solid hotpink;

  &:after {
    display: block;
    content: "";
    width: 7.458em;
    height: 0.83em;
    background-color: white;
    position: relative;
    top: -0.65em;
    left: 0.52em;
    z-index: -1;
  }

  @media only screen and (max-width: 1194px) {
    &:after {
      margin-bottom: 5px;
    }
  }

  // to offset animations
  opacity: 0;
`

const ValueHeading = styled.p`
  // border: 1px solid hotpink;

  display: flex;
  justify-content: center;
  align-items: center;

  font-family: futura-pt-bold, sans-serif;
  font-size: 72px;
  font-size: 3.75vw;
  line-height: 1;
  letter-spacing: 0.79px;

  margin-bottom: 10px;

  position: relative;

  .changing-word {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 250px;
    height: 250px;
    width: 3.472em;
    height: 3.472em;

    margin: 0 0.3em;

    position: relative;

    background-color: #f8e340;
    border-radius: 50%;

    span.word {
      ${firstInStyles}
    }

    span.underline {
      background-color: #54575a;
      width: 101%;
      height: 3px;
      position: absolute;
      top: 61%;
    }
  }

  @media only screen and (max-width: 1194px) {
    font-size: 39px;
    letter-spacing: 0.43px;
  }

  @media only screen and (max-width: 640px) {
    // border: 1px solid lightblue;
    display: block;
    text-align: center;

    margin-bottom: -10px;

    margin-bottom: 1rem;

    .changing-word {
      display: inline-flex;
      margin: 0;
    }

    .mobile-offset {
      display: inline-block;
      position: relative;
      top: -5.5vh;
    }
  }

  // to offset animations
  opacity: 0;
`

const ScrollContainer = styled.div`
  // border: 1px solid blue;

  // set font size for height
  font-size: 30px;
  font-size: 1.5625vw;

  @media only screen and (max-width: 1194px) {
    font-size: 18px;
  }

  height: 4em;
  overflow: hidden;
  position: relative;

  -webkit-mask-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 2%,
    rgba(0, 0, 0, 1),
    rgba(0, 0, 0, 0) 98%
  );

  mask-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 2%,
    rgba(0, 0, 0, 1),
    rgba(0, 0, 0, 0) 98%
  );

  // &:after {
  //   content: "";
  //   width: 100%;
  //   height: 100%;
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   background: linear-gradient(
  //     to bottom,
  //     #fff1f0 2%,
  //     rgba(0, 0, 0, 0),
  //     #fff1f0 98%
  //   );
  // }

  // background-color: pink;

  // to offset animations
  opacity: 0;
`

const ValueDescription = styled.div`
  // border: 1px solid pink;

  font-size: 30px;
  font-size: 1.5625vw;
  line-height: 1.53;

  text-align: center;

  @media only screen and (max-width: 1194px) {
    font-size: 18px;
    line-height: 25.2px;
  }

  width: 100%;
  height: 100%;

  transform: translateY(1em);
  transition: transform 0.7s ease-in-out;

  &.position-one {
    transform: translateY(1em);
  }

  &.position-two {
    transform: translateY(-0.3em);
  }

  &.position-three {
    transform: translateY(-1.8em);
  }
`

const LightBlueBubble = styled.div`
  // border: 1px solid hotpink;
  border-radius: 50%;

  position: absolute;
  top: -35vh;
  left: -21.98vw;

  width: 769px;
  height: 736px;
  width: 40.052vw;
  height: 38.33vw;

  background: #f4f5f7;

  &.animate {
    ${bubbleZoomIn}
    animation: bubbleZoomIn 2s;
  }

  @media only screen and (max-width: 1194px) {
    width: 400px;
    height: 400px;
    width: 39.0625vw;
    height: 39.0625vw;

    top: -25vh;
    left: -19.53vw;
  }

  @media only screen and (max-width: 834px) {
    width: 400px;
    height: 400px;

    top: -24vh;
    left: -200px;
    z-index: -1;
  }
`

const DarkBlueBubble = styled.div`
  border-radius: 50%;

  position: absolute;
  top: 58vh;
  right: -19vw;

  width: 750px;
  height: 750px;
  width: 39.0625vw;
  height: 39.0625vw;

  background: #acbfdf;

  &.animate {
    ${bubbleZoomIn}
    animation: bubbleZoomIn 2s;
  }

  @media only screen and (max-width: 1194px) {
    width: 400px;
    height: 400px;
    width: 39.0625vw;
    height: 39.0625vw;

    top: 43vh;
    right: -19.53vw;
  }

  @media only screen and (max-width: 834px) {
    width: 300px;
    height: 300px;
    top: 50vh;
    right: -150px;
    z-index: -1;
  }

  @media only screen and (max-width: 375px) {
    top: 80vh;
  }
`

const Values = () => {
  const [ref, inView] = useInView({ threshold: 0.15 })
  const [wordIndex, setWordIndex] = useState(0)

  const nextWord = () => {
    if (window.scrollY > 2000 && wordIndex < 2) {
      setWordIndex(current => current + 1)
    } else if (wordIndex === 2) {
      setWordIndex(0)
    }
  }

  useEffect(() => {
    const intervalId = setInterval(nextWord, 3000)
    return () => clearInterval(intervalId)
  })

  return (
    <Container ref={ref} className={inView ? "animate" : null}>
      <div className="anchor-offset" id="values" />
      <LightBlueBubble className={inView ? "animate" : null} />

      <HeadingContainer>
        <Heading>What I Stand For</Heading>
        <p style={{ marginTop: "-1rem" }}>
          These are the values that guide my life and<br></br>the work I do!
        </p>
      </HeadingContainer>

      <ValueHeading className="value-heading">
        <span className="pink">One</span>{" "}
        {wordIndex === 0 && (
          <span className="changing-word">
            <span className="word">person</span>
            <span className="underline"></span>
          </span>
        )}
        {wordIndex === 1 && (
          <span className="changing-word">
            <span className="word">market</span>
            <span className="underline"></span>
          </span>
        )}
        {wordIndex === 2 && (
          <span className="changing-word">
            <span className="word">world</span>
            <span className="underline"></span>
          </span>
        )}{" "}
        can{" "}
        <span className="mobile-offset">
          &nbsp;encompass<span className="pink">&nbsp;all</span>.
        </span>
      </ValueHeading>

      <ScrollContainer className="value-desc-container">
        <ValueDescription
          className={`${wordIndex === 0 ? "position-one" : ""}
          ${wordIndex === 1 ? "position-two" : ""}
          ${wordIndex === 2 ? "position-three" : ""}`}
        >
          <span className={wordIndex === 0 ? "pink" : null}>
            You can be everything and anything you want to be.
          </span>
          <br></br>
          <span className={wordIndex === 1 ? "pink" : null}>
            There is room for everyone to succeed.
          </span>
          <br></br>
          <span className={wordIndex === 2 ? "pink" : null}>
            I believe in respect, inclusion and making all voices heard.
          </span>
        </ValueDescription>
      </ScrollContainer>

      <DarkBlueBubble className={inView ? "animate" : null} />
    </Container>
  )
}

export default Values
