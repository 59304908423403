import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"

import HeroAndAboutMe from "../components/HeroAndAboutMe.js"
import Services from "../components/Services.js"
import WhyCCShop from "../components/WhyCCShop.js"
import Values from "../components/Values.js"
import ContactAndFooter from "../components/ContactAndFooter.js"

const IndexPage = () => (
  <Layout>
    <Seo title="Joyful Web Development" />
    <HeroAndAboutMe />
    <Services />
    <WhyCCShop />
    <Values />
    <ContactAndFooter />

    {/* <StaticImage
      src="../images/gatsby-astronaut.png"
      width={300}
      quality={95}
      formats={["AUTO", "WEBP", "AVIF"]}
      alt="A Gatsby astronaut"
      style={{ marginBottom: `1.45rem` }}
    />
      <Link to="/page-2/">Go to page 2</Link> <br /> */}
  </Layout>
)

export default IndexPage
