import * as React from "react"
import styled from "styled-components"
import { useInView } from "react-intersection-observer"
import { Link } from "react-scroll"
import confetti from "canvas-confetti"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faMagic } from "@fortawesome/free-solid-svg-icons"

import {
  fadeIn,
  raiseIn,
  bubbleZoomIn,
  leftIn,
  rightIn,
  firstInStyles,
  secondInStyles,
  thirdInStyles,
  fourthInStyles,
} from "./animations"

import {
  float,
  bounceTwo,
  confettiShooter,
  swing,
  wiggle,
} from "./hero-animations"

import AboutMeImg from "../images/me-miami-2-blurred-bkg.jpg"

const Container = styled.div`
  // border: 1px solid hotpink;
  width: 100%;
  height: 170vh;
  background-color: #fedcda;

  background: linear-gradient(180deg, #fedcda 50%, #fff1f0 50%);

  z-index: 0;

  @media only screen and (max-width: 1440px) {
    height: 180vh;
  }

  @media only screen and (max-width: 1194px) {
    height: 165vh;
  }

  @media only screen and (max-width: 834px) {
    height: calc(70vh + 600px + 23px + 429px);
  }

  @media only screen and (max-width: 640px) {
    height: calc(100vh + 70.048vw + 21px + 650px);

    background: linear-gradient(180deg, #fedcda 30%, #fff1f0 30% 100%);
  }
`

const PinkBackground = styled.div`
  // border: 1px solid blue;
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 108vh;
  height: 102vh;
  background-color: #fedcda;

  @media only screen and (min-width: 641px) and (max-width: 834px) {
    // light pink block to cover dark pink container
    background-color: #fff1f0;
    top: 806px;
    height: 40vh;
  }
`

const TextContainer = styled.div`
  // border: 1px solid pink;
  width: 100%;
  height: 100vh;
  margin-left: 81px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  position: relative;
  z-index: 5;

  @media only screen and (max-width: 1194px) {
    margin-left: 65px;
  }

  @media only screen and (min-width: 641px) and (max-width: 834px) {
    margin-left: 42px;
    height: 806px;
  }

  @media only screen and (max-width: 640px) {
    margin: 0 auto;
    width: 87%;
  }

  &.animate {
    h1 {
      ${firstInStyles}
    }

    p {
      ${secondInStyles}
    }

    button {
      ${thirdInStyles}
    }
  }
`

const Description = styled.p`
  font-size: 30px;
  line-height: 46px;
  margin-top: 18px;
  margin-bottom: 46px;

  @media only screen and (max-width: 1440px) {
    font-size: 21px;
    line-height: 32px;
  }

  @media only screen and (max-width: 1194px) {
    margin-top: 7px;
    margin-bottom: 21px;
  }

  @media only screen and (max-width: 834px) {
    font-size: 18px;
    line-height: 26px;

    margin-bottom: 28px;
  }

  @media only screen and (max-width: 640px) {
    // border: 1px solid pink;
    margin-top: 8px;
    margin-bottom: 25px;

    font-size: 4.35vw;
    line-height: 1.44;
  }
`

const YellowBubble = styled.div`
  background-color: #f8e340;
  width: 737px;
  width: 38.4vw;
  height: 658px;
  height: 34.3vw;
  border-radius: 50%;

  position: absolute;
  top: -82px;
  top: -7.6vh;
  left: -200px;
  left: -10.45vw;

  z-index: 3;

  &.animate {
    ${bubbleZoomIn}
    animation: bubbleZoomIn 2s;
  }

  @media only screen and (max-width: 1194px) {
    width: 450px;
    height: 450px;

    // width: 43.95vw;
    // height: 43.95vw;

    // width: 58.59vh;
    // height: 58.59vh;

    top: -37px;
    top: -4.82vh;
    left: -161px;
    left: -15.73vw;
  }

  @media only screen and (min-width: 641px) and (max-width: 834px) {
    top: -36px;
    left: -242px;
  }

  @media only screen and (max-width: 640px) {
    width: 500px;
    width: 120.78vw;
    height: 500px;
    height: 120.78vw;

    width: 55.8vh;
    height: 55.8vh;

    top: -21px;
    top: -4.69vh;
    left: -293px;
    left: -70.78vw;

    left: -32.7vh;
  }
`

const GreenBubble = styled.div`
  background-color: #d4f8d0;
  width: 605px;
  width: 31.5vw;
  height: 580px;
  height: 30.25vw;
  border-radius: 50%;

  position: absolute;
  top: 215px;
  top: 20vh;
  left: 253px;
  left: 13.2vw;

  z-index: 4;

  &.animate {
    ${bubbleZoomIn}
    animation: bubbleZoomIn 2s;
  }

  @media only screen and (max-width: 1194px) {
    width: 411px;
    // width: 40.14vw;
    height: 405px;
    // height: 39.55vw;

    // width: 53.52vh;
    // height: 52.74vh;

    top: 157px;
    top: 20.45vh;
    left: 149px;
    left: 14.56vw;
  }

  @media only screen and (min-width: 641px) and (max-width: 834px) {
    top: 158px;
    left: 68px;
  }

  @media only screen and (max-width: 640px) {
    width: 411px;
    width: 99.28vw;
    height: 405px;
    height: 97.83vw;

    width: 45.87vh;
    height: 45.2vh;

    top: 210px;
    top: 23.44vh;
    left: 2px;
    left: 0.483vw;

    left: 0.22vh;
  }
`

const AquaBubble = styled.div`
  background-color: #aae7d6;
  width: 769px;
  width: 40.1vw;
  height: 736px;
  height: 38.35vw;
  border-radius: 50%;

  position: absolute;
  top: 494px;
  top: 45.8vh;
  left: -200px;
  left: -10.45vw;

  z-index: 2;

  &.animate {
    ${bubbleZoomIn}
    animation: bubbleZoomIn 2s;
  }

  @media only screen and (max-width: 1194px) {
    width: 503px;
    // width: 49.13vw;
    height: 468px;
    // height: 45.7vw;

    // width: 65.5vh;
    // height: 60.94vh;

    top: 359px;
    top: 46.75vh;
    left: -161px;
    left: -15.73vw;
  }

  @media only screen and (min-width: 641px) and (max-width: 834px) {
    top: 360px;
    left: -242px;
  }

  @media only screen and (max-width: 640px) {
    width: 542px;
    width: 130.92vw;
    height: 514px;
    height: 124.16vw;

    width: 60.49vh;
    height: 57.37vh;

    top: 448px;
    top: 50vh;
    left: -298px;
    left: -71.98vw;

    left: -33.26vh;
  }
`

const PinkLgBubble = styled.div`
  background-color: #fff1f0;
  width: 2053px;
  width: 106.95vw;
  height: 1952px;
  height: 101.7vw;
  border-radius: 50%;

  position: absolute;
  top: 191px;
  top: 17.7vh;
  left: 791px;
  left: 41.2vw;

  z-index: 1;

  &.animate {
    ${bubbleZoomIn}
    animation: bubbleZoomIn 2s;
  }

  @media only screen and (max-width: 1194px) {
    width: 1414px;
    // width: 138.09vw;
    height: 1238px;
    // height: 120.9vw;

    // width: 184.11vh;
    // height: 161.2vh;

    top: 197px;
    top: 25.66vh;
    left: 472px;
    left: 46.1vw;
  }

  @media only screen and (min-width: 641px) and (max-width: 834px) {
    // top: 198px;
    // left: 391px;

    display: none;
  }

  @media only screen and (max-width: 640px) {
    display: none;
  }
`

const PinkMdBubble = styled.div`
  background-color: #fedcda;
  width: 1923px;
  width: 100.2vw;
  height: 1404px;
  height: 73.15vw;
  border-radius: 50%;

  position: absolute;
  top: 505px;
  top: 46.76vh;
  left: 920px;
  left: 47.92vw;

  z-index: 2;

  &.animate {
    ${bubbleZoomIn}
    animation: bubbleZoomIn 2s;
  }

  @media only screen and (max-width: 1194px) {
    width: 1226px;
    // width: 119.73vw;
    height: 921px;
    // height: 89.95vw;

    // width: 159.64vh;
    // height: 119.92vh;

    top: 376px;
    top: 48.96vh;
    left: 516px;
    left: 50.4vw;
  }

  @media only screen and (min-width: 641px) and (max-width: 834px) {
    // top: 377px;
    // left: 435px;

    top: 651px;
    // top: 63.57vh;

    // left: 84px;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;

    width: 600px;
    height: 600px;

    z-index: 1;
  }

  @media only screen and (max-width: 640px) {
    width: 390px;
    width: 94.2vw;
    height: 390px;
    height: 94.2vw;

    width: 43.53vh;
    height: 43.53vh;

    top: 844px;
    top: 94.2vh;
    left: 12px;
    left: 2.9vw;

    // left: 1.339vh;

    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;

    z-index: 1;
  }
`

const PinkSmBubble = styled.div`
  background-color: #fff1f0;
  width: 750px;
  width: 39.1vw;
  height: 740px;
  height: 38.55vw;
  border-radius: 50%;

  position: absolute;
  top: 961px;
  top: 88.99vh;
  left: 920px;
  left: 47.92vw;

  z-index: 3;

  &.animate {
    ${bubbleZoomIn}
    animation: bubbleZoomIn 2s;
  }

  @media only screen and (max-width: 1194px) {
    width: 390px;
    // width: 38.09vw;
    height: 390px;
    // height: 38.09vw;

    // width: 50.78vh;
    // height: 50.78vh;

    top: 739px;
    top: 96.23vh;
    left: 512px;
    left: 50vw;
  }

  @media only screen and (min-width: 641px) and (max-width: 834px) {
    // top: 740px;
    // left: 431px;

    top: 624px;
    // top: 60.94vh;
    left: 524px;
    left: 68.23vw;

    // width: 390px;
    // height: 390px;
    width: 43.53vh;
    height: 43.53vh;
  }

  @media only screen and (max-width: 640px) {
    width: 390px;
    width: 94.2vw;
    height: 390px;
    height: 94.2vw;

    width: 43.53vh;
    height: 43.53vh;

    top: 744px;
    top: 83.04vh;
    left: 244px;
    left: 58.94vw;

    // left: 27.23vh;
  }
`

const MagicBubble = styled.button`
  background-color: #fff1f0;
  border: 17px solid #feddda;
  border-radius: 50%;
  width: 250px;
  width: 13.1vw;
  height: 250px;
  height: 13.1vw;

  position: absolute;
  top: 468px;
  top: 43.35vh;
  left: 1300px;
  left: 67.72vw;

  z-index: 6;
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;

  span {
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 24px;
    font-weight: 900;
    text-align: center;
    line-height: 30px;
  }

  &:hover {
    .icon-wrapper {
      transform: rotate(-45deg);
    }
  }

  &:active {
    .icon-wrapper {
      top: -7px;
    }
  }

  &.animate {
    ${bubbleZoomIn}
    ${float}
    animation: float 4s ease-in-out 2s infinite, bubbleZoomIn 2s;

    // ${bounceTwo}
    // animation: bounceTwo 2.5s ease infinite;

    // ${swing}
    // transform-origin: bottom center;
    // animation: swing 2s ease infinite;
  }

  @media only screen and (max-width: 1440px) {
    span {
      font-size: 15px;
      line-height: 19px;
    }
  }

  @media only screen and (max-width: 1194px) {
    border-width: 10px;

    width: 153px;
    height: 154px;

    top: 366px;
    top: 47.66vh;
    left: 762px;
    left: 74.42vw;
  }

  @media only screen and (min-width: 641px) and (max-width: 834px) {
    // top: 440px;
    // left: 550px;

    top: 547px;
    // top: 53.42vh;
    left: 526px;
    left: 68.49vw;
  }

  @media only screen and (max-width: 640px) {
    width: 153px;
    width: 36.96vw;
    height: 154px;
    height: 37.2vw;

    width: 17.08vh;
    height: 17.19vh;

    top: 667px;
    top: 74.44vh;
    left: 246px;
    left: 59.42vw;

    // left: 27.46vh;

    span {
      line-height: 1.27;
      font-size: 14px;
      letter-spacing: 0.5px;
    }
  }
`

const IconWrapper = styled.div`
  // border: 1px solid hotpink;

  // to set icon size
  font-size: 30px;

  // color: #feddda;

  margin-bottom: 10px;

  // for animations
  position: relative;
  transition: all 0.25s ease-in-out;

  // ${wiggle}

  // animation: wiggle 4s cubic-bezier(0, 1, 0, 1) infinite both;

  @media only screen and (max-width: 834px) {
    margin-bottom: 4px;
  }
`

const DesktopText = styled.span`
  display: inline;

  @media only screen and (max-width: 834px) {
    display: none;
  }
`

const MobileText = styled.span`
  display: none;

  @media only screen and (max-width: 834px) {
    display: inline;
  }
`

const AboutMeContainer = styled.div`
  width: 100%;
  height: auto;

  &.animate {
    .about-me-img {
      opacity: 0;
      ${fadeIn}
      ${rightIn}
      -webkit-animation: fadeIn 0.7s ease-in 0s forwards,
        rightIn 0.7s ease-in 0s forwards;
      animation: fadeIn 0.7s ease-in 0s forwards,
        rightIn 0.7s ease-in 0s forwards;
    }

    .about-me-text {
      ${leftIn}
      -webkit-animation: leftIn 0.7s ease-in 0s forwards;
      animation: leftIn 0.7s ease-in 0s forwards;
    }

    h2 {
      opacity: 0.5;
      ${fadeIn}
      ${leftIn}
      -webkit-animation: fadeIn 0.7s ease-in 0s forwards,
        leftIn 0.7s ease-in 0s forwards;
      animation: fadeIn 0.7s ease-in 0s forwards,
        leftIn 0.7s ease-in 0s forwards;
    }

    p {
      opacity: 0;
      ${fadeIn}
      ${leftIn}
      -webkit-animation: fadeIn 0.7s ease-in 0s forwards,
        leftIn 0.7s ease-in 0s forwards;
      animation: fadeIn 0.7s ease-in 0s forwards,
        leftIn 0.7s ease-in 0s forwards;
    }
  }
`

const AboutMeImgContainer = styled.div`
  width: 560px;
  width: 29.17vw;
  height: 560px;
  height: 29.17vw;
  border-radius: 50%;

  position: absolute;
  top: 1088px;
  top: 100.74vh;
  left: 948px;
  left: 49.375vw;

  z-index: 4;

  // to animate in
  opacity: 0;

  background-image: url(${AboutMeImg});
  background-repeat: no-repeat;
  background-size: 110%;
  background-position: 73% 50%;

  @media only screen and (max-width: 1194px) {
    width: 290px;
    height: 290px;

    top: 816px;
    top: 106.25vh;
    left: 516px;
    left: 50.39vw;
  }

  @media only screen and (min-width: 641px) and (max-width: 834px) {
    // top: 817px;
    // left: 435px;

    width: 400px;
    height: 400px;

    top: 842px;
    // top: 82.23vh;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
  }

  @media only screen and (max-width: 640px) {
    width: 290px;
    width: 70.048vw;
    height: 290px;
    height: 70.048vw;

    width: 33.37vh;
    height: 33.37vh;

    top: 939px;
    top: 104.799vh;
    left: 62px;
    left: 14.975vw;

    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
  }
`

const AboutMeTextBox = styled.div`
  // border: 1px solid red;
  width: 667px;
  width: 34.74vw;
  height: 580px;
  height: 53.7vh;
  border: 13px solid #fff1f0;
  border-radius: 60px;
  background-color: white;

  height: auto;

  position: absolute;
  top: 1101px;
  top: 101.944vh;
  left: 257px;
  left: 13.385vw;

  z-index: 4;

  // padding: 1.4rem 4.5rem;
  padding: 25px 81px;

  display: flex;
  flex-direction: column;
  justify-content: center;

  @media only screen and (max-width: 1440px) {
    // padding: 23px 48px;
    padding: 1.5em 2.5em;
  }

  @media only screen and (max-width: 1194px) {
    border-width: 7px;
    border-radius: 46px;

    // top: 747px;
    // top: 97.27vh;
    top: 101vh;
    left: 64px;
    left: 6.25vw;

    width: 447px;
    width: 43.65vw;
    height: 429px;
    height: auto;

    padding: 15px 34px;
  }

  @media only screen and (min-width: 641px) and (max-width: 834px) {
    // top: 784px;
    // left: 42px;

    // width: 389px;
    // height: 356px;

    border-color: #fedcda;

    top: 1274px;
    // top: 124.41vh;

    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;

    width: 447px;
    height: auto;

    align-items: center;
    text-align: center;
  }

  @media only screen and (max-width: 640px) {
    border-color: #fedcda;
    // width: 377px;
    // width: 87%;
    width: 90vw;
    height: auto;

    top: 1250px;
    top: 139.51vh;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;

    padding: 13px 15px;

    align-items: center;
    text-align: center;
  }

  // to offset animation
  -webkit-transform: translateX(-5px);
  transform: translateX(-5px);
`

const AboutMeHeading = styled.h2`
  font-family: futura-pt;
  font-size: 42px;
  font-weight: 600;
  line-height: 46px;
  margin-bottom: 20px;

  // to animate in
  opacity: 0.5;

  @media only screen and (max-width: 1440px) {
    font-size: 24px;
    line-height: 26px;
  }

  @media only screen and (max-width: 1194px) {
    margin-bottom: 10px;
  }
`

const AboutMeDescription = styled.p`
  // border: 1px solid pink;
  width: 99%;

  // to animate in
  opacity: 0.5;

  @media only screen and (max-width: 1194px) {
    width: 100%;
  }
`

const HeroAndAboutMe = () => {
  const [heroRef, heroInView] = useInView({ threshold: 0.15 })
  const [aboutRef, aboutInView] = useInView({ threshold: 0.15 })

  return (
    <Container id="top">
      <TextContainer ref={heroRef} className={heroInView ? "animate" : null}>
        <h1>
          Joyful web development to <br></br>
          <span className="pink">empower</span> your business
        </h1>
        <Description>
          You already have everything within you to succeed.
          <br></br>
          Let me share your magic with the web.
        </Description>

        <Link to="about" spy={true} smooth={true}>
          <button>Tell me more!</button>
        </Link>
      </TextContainer>
      <GreenBubble className={heroInView ? "animate" : null}></GreenBubble>
      <YellowBubble className={heroInView ? "animate" : null}></YellowBubble>
      <AquaBubble className={heroInView ? "animate" : null}></AquaBubble>
      <PinkLgBubble className={heroInView ? "animate" : null}></PinkLgBubble>
      <PinkMdBubble className={heroInView ? "animate" : null}></PinkMdBubble>
      <PinkSmBubble className={heroInView ? "animate" : null}></PinkSmBubble>
      <MagicBubble
        className={heroInView ? "animate" : null}
        onClick={() =>
          confetti({
            particleCount: 250,
            spread: 150,
            origin: { x: 0.74, y: 0.5 },
            // angle: 135,
            shapes: ["circle"],
            // colors: [
            //   "#D51280",
            //   "#F8E340",
            //   "#D4F8D0",
            //   "#AAE7D6",
            //   "#D4F8D0",
            //   "#D2FAF9",
            //   // "#ACBFDF",
            //   // "#F4F5F7",
            // ],
          })
        }
      >
        <span className="pink">
          <IconWrapper className="icon-wrapper">
            <FontAwesomeIcon icon={faMagic} />
          </IconWrapper>
          <DesktopText>Click for</DesktopText>
          <MobileText>Tap for</MobileText>
          <br></br>
          Joy
        </span>
      </MagicBubble>

      <PinkBackground></PinkBackground>

      <div className="anchor-offset" id="about" />
      <AboutMeContainer className={aboutInView ? "animate" : null}>
        <AboutMeImgContainer className="about-me-img"></AboutMeImgContainer>
        <AboutMeTextBox ref={aboutRef} className="about-me-text">
          <AboutMeHeading>Hi &#128075; I'm Cece!</AboutMeHeading>
          <AboutMeDescription>
            I’m a <span className="pink">developer</span>, and I will be your{" "}
            <span className="pink">#1 hype woman</span>!<br></br>
            <br></br>I get so excited when I meet people pursuing their passions
            because I know from personal experience, it’s not easy. Having your
            own business means you’re fully owning your potential and for that,{" "}
            <span className="pink">you’re beyond amazing.</span>
            <br></br>
            <br></br>
            As for myself, I’m here to bring your dream to life on the web!
            <br></br>
            <br></br>
            <span style={{ fontWeight: `600` }}>
              Whether you’re just starting out or looking to level up, I want to
              <span className="pink"> empower</span> you with an amazing web
              presence that will get you there.
            </span>
            <br></br>
            <br></br>
            Think side character vibes to your main character energy!
            <br></br>
            {/* <br></br>
            <span className="pink" style={{ fontWeight: `900` }}>
              More about me {">"}
            </span> */}
          </AboutMeDescription>
        </AboutMeTextBox>
      </AboutMeContainer>
    </Container>
  )
}

export default HeroAndAboutMe
