import React, { useState, useEffect } from "react"
import { useForm } from "react-hook-form"
import styled from "styled-components"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheckCircle, faTimesCircle } from "@fortawesome/free-solid-svg-icons"

import {
  Input,
  Button,
  ErrorMsg,
  Select,
  StyledNumberFormat,
  RadioLabel,
  RadioWrapper,
  QuestionText,
  QuestionLabel,
  TextArea,
  RadioGroupWrapper,
  DateInput,
  CheckboxLabel,
  CheckboxGroupWrapper,
  CheckboxWrapper,
} from "./Inputs"

const MAX_STEPS = 3

const Container = styled.div`
  // border: 1px solid lightblue;
  width: 616px;
  height: auto;
  min-height: 360px;

  display: flex;
  flex-direction: column;

  span {
    // border: 1px solid lightpink;
    display: inline-block;
    width: 100%;

    position: relative;

    &.extra-margin-top {
      margin-top: 1rem;
    }

    &.extra-margin-bottom {
      margin-bottom: 1rem;
    }
  }

  @media only screen and (max-width: 1194px) {
    width: 548px;
  }

  @media only screen and (max-width: 834px) {
    width: 100%;
  }
`

const Row = styled.div`
  display: flex;

  @media only screen and (max-width: 640px) {
    display: block;
  }
`

const FormStatus = styled.div`
  // border: 1px solid lightblue;
  width: auto;
  width: 180px;
  height: auto;

  position: absolute;
  left: -210px;

  font-size: 20px;
  font-size: 1.04167vw;
  line-height: 1.5;
  letter-spacing: 1.6px;
  text-transform: uppercase;

  p {
    // border: 1px solid lightblue;
    margin-bottom: 1.15em;
    display: inline-block;

    &:after {
      content: "";
      opacity: 0;
      width: 0;
      height: 1px;
      display: block;
      background: #555555;
      transition: all 1s ease-in-out;
    }
  }

  .underlined {
    &:after {
      opacity: 1;
      width: 100%;
    }
  }

  @media only screen and (max-width: 1440px) {
    width: 150px;

    left: -160px;
  }

  @media only screen and (max-width: 834px) {
    display: none;
  }
`

const MobileFormStatus = styled.p`
  display: none;

  @media only screen and (max-width: 834px) {
    // border: 1px solid pink;
    display: inline-block;
    height: 100%;
    width: 7rem;
    margin-bottom: 1em;
    background-color: #fff;
    background-color: #fedcda;
    border-radius: 20px;
    padding: 0.2rem;

    align-self: center;

    text-align: center;
    text-transform: uppercase;
  }
`

const FormSubmittedSection = styled.section`
  // border: 1px solid lightpink;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding: 1em 0;

  text-align: center;

  span {
    display: inline;
  }

  p {
    // border: 1px solid lightpink;
    width: 70%;
    margin-bottom: 0.6rem;
  }

  h4 {
    margin: 0.6rem 0;
  }

  @media only screen and (max-width: 1440px) {
    p {
      width: 60%;
    }
  }

  @media only screen and (max-width: 1194px) {
    p {
      width: 70%;
    }
  }

  @media only screen and (max-width: 834px) {
    p {
      width: 50%;
    }
  }

  @media only screen and (max-width: 640px) {
    p {
      width: 100%;
    }
  }
`

const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

const ContactFormMultiStep = () => {
  const [formStep, setFormStep] = useState(0)
  const [submitSuccess, setSubmitSuccess] = useState(null)
  const {
    watch,
    register,
    trigger,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onTouched",
  })

  const watchHasTargetDate = watch("hasTargetDate", "No")

  const getCurrentDate = () => {
    const date = new Date()
    const timezoneAdjustDate = new Date(
      date.getTime() - date.getTimezoneOffset() * 60000
    )
      .toISOString()
      .split("T")[0]
    return timezoneAdjustDate
  }

  const nextFormStep = () => {
    setFormStep(current => current + 1)
  }

  const prevFormStep = () => {
    setFormStep(current => current - 1)
  }

  const onSubmit = (data, e) => {
    const sendToNetlify = async () => {
      try {
        const sendData = fetch("/", {
          method: "POST",
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
          body: encode({
            "form-name": "Contact Form",
            "First Name": data.firstName,
            "Last Name": data.lastName,
            "Preferred Pronoun": data.pronoun,
            Email: data.email,
            "Phone Number": data.phone,
            "Business Name": data.busName,
            Website: data.website,
            "Looking To": data.lookingTo,
            "Products/Services": data.productsServices,
            Inspiration: data.inspiration,
            "Facebook URL": data.facebook,
            "Instagram URL": data.instagram,
            "Help With": data.helpWith,
            Goals: data.goals,
            Budget: data.budget,
            "Has Target Date": data.hasTargetDate,
            "Target Date": data.targetDate,
            "Personal Referral":
              data.trafficSource.includes("Personal Referral"),
            Instagram: data.trafficSource.includes("Instagram"),
            Facebook: data.trafficSource.includes("Facebook"),
            Ads: data.trafficSource.includes("Ads"),
            Google: data.trafficSource.includes("Google"),
            "bot-field": data.botField,
          }),
        })

        const response = await sendData

        if (!response.ok) {
          throw new Error(`${response.status} ${response.statusText}`)
        }

        await setSubmitSuccess(true)
        await nextFormStep()
        await reset()
      } catch (e) {
        await setSubmitSuccess(false)
        await nextFormStep()
        console.log(e)
      }
    }

    sendToNetlify()
    e.preventDefault()
  }

  return (
    <Container>
      <FormStatus>
        <p className={formStep === 0 ? "underlined" : null}>Contact Info</p>
        <p className={formStep === 1 ? "underlined" : null}>Tell me more</p>
        <p className={formStep === 2 ? "underlined" : null}>How I can help</p>
      </FormStatus>

      {formStep < MAX_STEPS && (
        <MobileFormStatus>
          Step {formStep + 1} of {MAX_STEPS}
        </MobileFormStatus>
      )}

      <form
        onSubmit={handleSubmit(onSubmit)}
        name="Contact Form"
        method="POST"
        data-netlify="true"
        netlify-honeypot="bot-field"
        data-netlify-recaptcha="true"
      >
        <input type="hidden" name="form-name" value="Contact Form" />
        <p
          style={{
            position: `absolute`,
            overFlow: `hidden`,
            clip: "rect(0 0 0 0)",
            height: "1px",
            width: "1px",
            margin: "-1px",
          }}
        >
          <label>
            Don’t fill this out if you’re human:{" "}
            <input name="bot-field" {...register("botField")} />
          </label>
        </p>

        {formStep === 0 && (
          <section className="contact-info">
            <Row>
              <span>
                {/* <label for="firstName">First Name*:</label> */}
                <Input
                  type="text"
                  id="firstName"
                  name="First Name"
                  placeholder="*First name"
                  className={errors.firstName ? "err" : null}
                  required
                  {...register("firstName", {
                    required: "First name is required",
                  })}
                ></Input>
                {errors.firstName && (
                  <ErrorMsg>{errors.firstName?.message}</ErrorMsg>
                )}
              </span>

              <span>
                {/* <label for="lastName">Last Name*:</label> */}
                <Input
                  type="text"
                  id="lastName"
                  name="Last Name"
                  placeholder="*Last name"
                  className={errors.lastName ? "err" : null}
                  required
                  {...register("lastName", {
                    required: "Last name is required",
                  })}
                ></Input>
                {errors.lastName && (
                  <ErrorMsg>{errors.lastName?.message}</ErrorMsg>
                )}
              </span>
            </Row>

            <Row>
              <span>
                {/* <label for="pronoun">Preferred Pronoun:</label> */}
                <Select
                  id="pronoun"
                  name="Preferred Pronoun"
                  placeholder="Preferred Pronoun"
                  {...register("pronoun")}
                >
                  <option value="" disabled selected>
                    Preferred Pronoun
                  </option>
                  <option value="She">She/Her</option>
                  <option value="He">He/Him</option>
                  <option value="They">They/Them</option>
                  <option value="N/A">Prefer Not To Say</option>
                </Select>
              </span>
            </Row>

            <Row>
              <span>
                {/* <label for="email">Email*:</label> */}
                <Input
                  type="email"
                  id="email"
                  name="Email"
                  placeholder="*Email"
                  className={errors.email ? "err" : null}
                  required
                  {...register("email", {
                    required: "Email is required",
                    pattern: {
                      value: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
                      message: "Please enter a valid email",
                    },
                  })}
                ></Input>
                {errors.email && <ErrorMsg>{errors.email?.message}</ErrorMsg>}
              </span>
            </Row>

            <Row>
              <span>
                {/* <label for="phone">Phone Number*:</label> */}
                <StyledNumberFormat
                  format="+1 (###)### - ####"
                  type="tel"
                  id="phone"
                  name="Phone Number"
                  placeholder="*Phone number"
                  className={errors.phone ? "err" : null}
                  required
                  {...register("phone", {
                    required: "Phone number is required",
                    pattern: {
                      value: /\+1 \([0-9]{3}\)[0-9]{3} - [0-9]{4}/,
                      message: "Please enter a valid phone number",
                    },
                  })}
                ></StyledNumberFormat>
                {errors.phone && <ErrorMsg>{errors.phone?.message}</ErrorMsg>}
              </span>
            </Row>
          </section>
        )}

        {formStep === 1 && (
          <section className="tell-me-more">
            <Row>
              <span>
                {/* <label for="busName">Business Name*:</label> */}
                <Input
                  type="text"
                  id="busName"
                  name="Business Name"
                  placeholder="*Business name"
                  className={errors.busName ? "err" : null}
                  required
                  {...register("busName", {
                    required: "Business name is required",
                  })}
                ></Input>
                {errors.busName && (
                  <ErrorMsg>{errors.busName?.message}</ErrorMsg>
                )}
              </span>

              <span>
                {/* <label for="url">
                  Link to existing website/shop (if applicable):
                </label> */}
                <Input
                  type="url"
                  id="url"
                  name="Website"
                  placeholder="Website URL (if applicable)"
                  className={`width-100 ${errors.website ? "err" : null}`}
                  {...register("website", {
                    pattern: {
                      value:
                        /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/,
                      message: "Please enter a valid url",
                    },
                  })}
                ></Input>
                {errors.website && (
                  <ErrorMsg className="width-100">
                    {errors.website?.message}
                  </ErrorMsg>
                )}
              </span>
            </Row>

            <Row>
              <span>
                {/* <label for="facebook">Facebook URL (if applicable):</label> */}
                <Input
                  type="url"
                  id="facebook"
                  name="Facebook URL"
                  placeholder="Facebook URL"
                  className={errors.facebook ? "err" : null}
                  {...register("facebook", {
                    pattern: {
                      value:
                        /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/,
                      message: "Please enter a valid url",
                    },
                  })}
                ></Input>
                {errors.facebook && (
                  <ErrorMsg>{errors.facebook?.message}</ErrorMsg>
                )}
              </span>

              <span>
                {/* <label for="instagram">Instagram URL (if applicable):</label> */}
                <Input
                  type="url"
                  id="instagram"
                  name="Instagram URL"
                  placeholder="Instagram URL"
                  className={errors.instagram ? "err" : null}
                  {...register("instagram", {
                    pattern: {
                      value:
                        /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/,
                      message: "Please enter a valid url",
                    },
                  })}
                ></Input>
                {errors.instagram && (
                  <ErrorMsg>{errors.instagram?.message}</ErrorMsg>
                )}
              </span>
            </Row>

            <Row>
              <span className="extra-margin-top extra-margin-bottom">
                <RadioGroupWrapper style={{ display: "flex" }}>
                  <QuestionText>*I'm looking to:</QuestionText>
                  <div>
                    <RadioWrapper>
                      <input
                        type="radio"
                        id="startBus"
                        name="Looking To"
                        value="Start"
                        required
                        {...register("lookingTo", {
                          required: "Please select an option",
                        })}
                      ></input>
                      <RadioLabel for="startBus">Start my business</RadioLabel>
                    </RadioWrapper>
                    <RadioWrapper>
                      <input
                        type="radio"
                        id="levelUpBus"
                        name="Looking To"
                        value="Level Up"
                        required
                        {...register("lookingTo", {
                          required: "Please select an option",
                        })}
                      ></input>
                      <RadioLabel for="levelUpBus">
                        Level up my business
                      </RadioLabel>
                    </RadioWrapper>
                  </div>
                </RadioGroupWrapper>
                {errors.lookingTo && (
                  <ErrorMsg className="width-100 left">
                    {errors.lookingTo?.message}
                  </ErrorMsg>
                )}
              </span>
            </Row>

            <Row>
              <span>
                <QuestionLabel for="productsServices">
                  *Please describe your products and/or services:
                </QuestionLabel>
                <TextArea
                  id="productsServices"
                  name="Products/Services"
                  rows="2"
                  cols="30"
                  className={errors.productsServices ? "err" : null}
                  required
                  {...register("productsServices", {
                    required:
                      "Please provide a description, I'd love to know more!",
                  })}
                ></TextArea>
                {errors.productsServices && (
                  <ErrorMsg className="left width-100">
                    {errors.productsServices?.message}
                  </ErrorMsg>
                )}
              </span>
            </Row>

            <Row>
              <span>
                <QuestionLabel for="inspiration">
                  *What inspired you to start your business?
                </QuestionLabel>
                <TextArea
                  id="inspiration"
                  name="Inspiration"
                  rows="2"
                  cols="30"
                  className={errors.inspiration ? "err" : null}
                  required
                  {...register("inspiration", {
                    required:
                      "Please provide a response, I'd love to know more!",
                  })}
                ></TextArea>
                {errors.inspiration && (
                  <ErrorMsg className="left width-100">
                    {errors.inspiration?.message}
                  </ErrorMsg>
                )}
              </span>
            </Row>
          </section>
        )}

        {formStep === 2 && (
          <section className="how-can-i-help">
            <Row>
              <span>
                <RadioGroupWrapper>
                  <QuestionText>*I would like help with my:</QuestionText>
                  <div>
                    <RadioWrapper>
                      <input
                        type="radio"
                        id="website"
                        name="Help With"
                        value="Website"
                        required
                        {...register("helpWith", {
                          required: "Please select an option",
                        })}
                      ></input>
                      <RadioLabel for="website">Website</RadioLabel>
                    </RadioWrapper>
                    <RadioWrapper>
                      <input
                        type="radio"
                        id="shopify"
                        name="Help With"
                        value="Shopify"
                        required
                        {...register("helpWith", {
                          required: "Please select an option",
                        })}
                      ></input>
                      <RadioLabel for="shopify">Shopify Store</RadioLabel>
                    </RadioWrapper>
                  </div>
                </RadioGroupWrapper>
                {errors.helpWith && (
                  <ErrorMsg className="left">
                    {errors.helpWith?.message}
                  </ErrorMsg>
                )}
              </span>
            </Row>

            <Row>
              <span>
                <QuestionLabel for="goals">
                  *What goal(s) can I help you achieve?
                </QuestionLabel>
                <TextArea
                  id="goals"
                  name="Goals"
                  rows="2"
                  cols="30"
                  className={errors.goals ? "err" : null}
                  required
                  {...register("goals", {
                    required:
                      "Please provide a response, I'd love to know how I can help!",
                  })}
                ></TextArea>
                {errors.goals && (
                  <ErrorMsg className="left width-100">
                    {errors.goals?.message}
                  </ErrorMsg>
                )}
              </span>
            </Row>

            <Row>
              <span>
                {/* <label for="budget">Your budget*:</label> */}
                <StyledNumberFormat
                  prefix={"$"}
                  thousandSeparator={true}
                  decimalScale={0}
                  fixedDecimalScale={true}
                  id="budget"
                  name="Budget"
                  placeholder="*Your budget"
                  className={errors.budget ? "err" : null}
                  required
                  {...register("budget", { required: "Budget is required" })}
                ></StyledNumberFormat>
                {errors.budget && <ErrorMsg>{errors.budget?.message}</ErrorMsg>}
              </span>
            </Row>

            <Row>
              <span className="">
                <QuestionText>
                  *Do you have a target date to launch a new website/store?:
                </QuestionText>
                <RadioGroupWrapper className="width-100">
                  <RadioWrapper>
                    <input
                      type="radio"
                      id="flexDate"
                      name="Has Target Date"
                      value="YesFlexible"
                      required
                      {...register("hasTargetDate", {
                        required: "Please select an option",
                      })}
                    ></input>
                    <RadioLabel for="flexDate">
                      Yes, but it's flexible
                    </RadioLabel>
                  </RadioWrapper>

                  <RadioWrapper>
                    <input
                      type="radio"
                      id="yesDate"
                      name="Has Target Date"
                      value="Yes"
                      required
                      {...register("hasTargetDate", {
                        required: "Please select an option",
                      })}
                    ></input>
                    <RadioLabel for="yesDate">Yes</RadioLabel>
                  </RadioWrapper>

                  <RadioWrapper>
                    <input
                      type="radio"
                      id="noDate"
                      name="Has Target Date"
                      value="No"
                      required
                      {...register("hasTargetDate", {
                        required: "Please select an option",
                      })}
                    ></input>
                    <RadioLabel for="noDate">No</RadioLabel>
                  </RadioWrapper>
                </RadioGroupWrapper>
                {errors.hasTargetDate && (
                  <ErrorMsg className="left">
                    {errors.hasTargetDate?.message}
                  </ErrorMsg>
                )}
              </span>
            </Row>

            {/* if responded yes or yes flexible to above question */}
            {(watchHasTargetDate === "YesFlexible" ||
              watchHasTargetDate === "Yes") && (
              <Row>
                <span>
                  {/* <label for="date">Target Date*:</label> */}
                  <DateInput
                    type="date"
                    id="date"
                    name="Target Date"
                    placeholder="*Target Date: &nbsp;"
                    className={errors.targetDate ? "err" : null}
                    required
                    {...register("targetDate", {
                      required: "Target date is required",
                      validate: {
                        isFutureDate: v =>
                          v > getCurrentDate() ||
                          "Please provide a date in the future",
                      },
                    })}
                  ></DateInput>
                  {errors.targetDate && (
                    <ErrorMsg>{errors.targetDate?.message}</ErrorMsg>
                  )}
                </span>
              </Row>
            )}

            <Row>
              <span className="extra-margin-top">
                <QuestionText>
                  *How did you hear about The CC Shop? (check all that apply):
                </QuestionText>

                <CheckboxGroupWrapper className="width-100">
                  <CheckboxWrapper>
                    <input
                      type="checkbox"
                      id="personalRef"
                      name="Personal Referral"
                      value="Personal Referral"
                      {...register("trafficSource", {
                        validate: {
                          atLeastOne: v =>
                            v.length > 0 || "Please select at least one option",
                        },
                      })}
                    ></input>
                    <CheckboxLabel for="personalRef">
                      Personal Referral
                    </CheckboxLabel>
                  </CheckboxWrapper>

                  <CheckboxWrapper>
                    <input
                      type="checkbox"
                      id="ig"
                      name="Instagram"
                      value="Instagram"
                      {...register("trafficSource")}
                    ></input>
                    <CheckboxLabel for="ig">Instagram</CheckboxLabel>
                  </CheckboxWrapper>

                  <CheckboxWrapper>
                    <input
                      type="checkbox"
                      id="fb"
                      name="Facebook"
                      value="Facebook"
                      {...register("trafficSource")}
                    ></input>
                    <CheckboxLabel for="fb">Facebook</CheckboxLabel>
                  </CheckboxWrapper>

                  <CheckboxWrapper>
                    <input
                      type="checkbox"
                      id="ads"
                      name="Ads"
                      value="Ads"
                      {...register("trafficSource")}
                    ></input>
                    <CheckboxLabel for="ads">Ads</CheckboxLabel>
                  </CheckboxWrapper>

                  <CheckboxWrapper>
                    <input
                      type="checkbox"
                      id="google"
                      name="Google"
                      value="Google"
                      {...register("trafficSource")}
                    ></input>
                    <CheckboxLabel for="google">Google</CheckboxLabel>
                  </CheckboxWrapper>
                </CheckboxGroupWrapper>
                {errors.trafficSource && (
                  <ErrorMsg className="left">
                    {errors.trafficSource?.message}
                  </ErrorMsg>
                )}
              </span>
            </Row>
          </section>
        )}

        {formStep === 3 && submitSuccess === true && (
          <FormSubmittedSection className="form-submitted">
            <div style={{ fontSize: "48px" }}>
              <FontAwesomeIcon icon={faCheckCircle} color="#04AA6D" size="lg" />
            </div>
            <h4>Thank you for connecting with me!</h4>
            <p>
              I appreciate the time you took to share a little bit about
              yourself and your business with me. I can't wait to continue this
              conversation with you!
              <br></br>
              <br></br>
              I'll be in contact within{" "}
              <span className="pink">1-3 business days</span>, if not sooner.
              <br></br>I hope you have an amazing rest of your day! &#x1F60A;
              <br></br>
              <span style={{ fontWeight: `600` }}>- Cece</span>
            </p>
          </FormSubmittedSection>
        )}

        {formStep === 3 && submitSuccess === false && (
          <FormSubmittedSection className="submit-error">
            <div style={{ fontSize: "48px" }}>
              <FontAwesomeIcon icon={faTimesCircle} color="red" size="lg" />
            </div>
            <h4>Oh no, I couldn't receive your submission!</h4>
            <p>
              We experienced an error while submitting your form, I'm so sorry
              for the trouble! Please go back to submit again, or try again at a
              later time.
              <br></br>
              <br></br>
              If you continue to experience this problem, please feel free to
              email myself directly at{" "}
              <a
                href="mailto: info@ccshop.com"
                style={{ textTransform: `none` }}
                className="pink"
              >
                info@ccshop.com
              </a>
              .<br></br>
              Thank you for your patience!
            </p>
            <Button onClick={prevFormStep} type="button" value="Back"></Button>
          </FormSubmittedSection>
        )}

        <div
          style={{
            // border: `1px solid pink`,
            display: `flex`,
            justifyContent: `space-between`,
            alignItems: `center`,
          }}
        >
          {(formStep === 1 || formStep === 2) && (
            <Button onClick={prevFormStep} type="button" value="Back"></Button>
          )}

          {formStep < 2 && (
            <Button
              onClick={async () => {
                const result = await trigger()

                if (result) {
                  const nextStep = await nextFormStep()
                  return nextStep
                } else {
                  return result
                }
              }}
              type="button"
              value="Next"
            ></Button>
          )}

          {formStep === 2 && (
            <Button
              onClick={() => {
                trigger()
              }}
              type="submit"
              value="Submit"
            ></Button>
          )}
        </div>
      </form>
    </Container>
  )
}

export default ContactFormMultiStep
