import * as React from "react"
import styled from "styled-components"
import { Link } from "react-scroll"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faCheckCircle,
  faCaretRight,
  faCaretLeft,
} from "@fortawesome/free-solid-svg-icons"

import Slider from "react-slick"

import { useInView } from "react-intersection-observer"

import {
  fadeIn,
  firstInStyles,
  secondInStyles,
  thirdInStyles,
  leftRaiseIn,
  rightRaiseIn,
} from "./animations"

import WebsiteImg from "../images/services/website-icon.svg"
import ShopifyImg from "../images/services/shop-icon.svg"

const Container = styled.div`
  // border: 1px solid hotpink;
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;

  // to stack on top of pink bubbles from above section
  position: relative;
  z-index: 1;

  &.animate {
    .yellow-bubble {
      ${firstInStyles}
    }

    .purple-bubble {
      ${secondInStyles}
    }

    @media only screen and (min-width: 835px) {
      .green-card {
        opacity: 0;
        ${fadeIn}
        ${leftRaiseIn}
      -webkit-animation: fadeIn 0.7s ease-in 0.3s forwards,
      leftRaiseIn 0.7s ease-in 0.3s forwards;

        animation: fadeIn 0.7s ease-in 0.3s forwards,
          leftRaiseIn 0.7s ease-in 0.3s forwards;

        // > :nth-child(2) {
        //   ${thirdInStyles}
        // }
      }

      .blue-card {
        opacity: 0;
        ${fadeIn}
        ${rightRaiseIn}
      -webkit-animation: fadeIn 0.7s ease-in 0.3s forwards,
      rightRaiseIn 0.7s ease-in 0.3s forwards;

        animation: fadeIn 0.7s ease-in 0.3s forwards,
          rightRaiseIn 0.7s ease-in 0.3s forwards;
      }
    }

    @media only screen and (max-width: 834px) {
      .mobile-card-container {
        ${secondInStyles}
      }
    }
  }
`

const YellowBubble = styled.div`
  position: absolute;
  width: 480px;
  height: 480px;
  background-color: #f8e340;
  border-radius: 50%;

  display: flex;
  justify-content: center;
  align-items: center;

  @media only screen and (max-width: 1440px) {
    width: 25vw;
    height: 25vw;
  }

  @media only screen and (max-width: 1194px) {
    width: 260px;
    height: 260px;
  }

  // to offset animations
  opacity: 0;
`

const Heading = styled.h2`
  // border: 1px solid hotpink;
  font-family: futura-pt-bold, sans-serif;
  font-size: 42px;
  font-weight: 700;
  line-height: 46px;

  margin-top: -55%;

  @media only screen and (max-width: 1440px) {
    font-size: 2.2vw;
    line-height: 1.09;
  }

  @media only screen and (max-width: 1194px) {
    font-size: 24px;
    line-height: 29px;
  }
`

const PurpleBubble = styled.div`
  position: absolute;
  width: 180px;
  height: 180px;
  background-color: #acbfdf;
  border-radius: 50%;

  display: flex;
  justify-content: center;
  align-items: center;

  z-index: 2;

  span {
    font-size: 30px;
    line-height: 46px;
    font-weight: 600;
    color: white;

    font-size: 1.58vw;
    line-height: 1.53;
  }

  @media only screen and (max-width: 1440px) {
    width: 9.375vw;
    height: 9.375vw;
  }

  @media only screen and (max-width: 1194px) {
    width: 90px;
    height: 90px;

    span {
      font-size: 16px;
      line-height: 24px;
    }
  }

  @media only screen and (max-width: 834px) {
    display: none;
  }

  // to offset animations
  opacity: 0;
  top: calc(240px - 90px);

  @media only screen and (max-width: 1440px) {
    top: calc(12.5vw - 4.6875vw);
  }

  @media only screen and (max-width: 1194px) {
    top: calc(130px - 45px);
  }
`

const CardContainer = styled.div`
  // border: 1px solid hotpink;
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;

  z-index: 1;

  // half of yellow bubble height, then adjusted
  margin-top: 165px;

  @media only screen and (max-width: 1440px) {
    // half of yellow bubble height, then adjusted
    margin-top: 15.277vh;
  }

  @media only screen and (max-width: 1194px) {
    // half of yellow bubble height, then adjusted
    margin-top: 85px;
  }

  @media only screen and (max-width: 834px) {
    display: none;
  }
`

const MobileCardContainer = styled.div`
  display: none;

  @media only screen and (max-width: 834px) {
    // border: 1px solid blue;
    display: block;

    width: 100%;
    min-width: 377px;
    width: 450px;
    height: 100%;

    margin-top: 85px;

    // to offset animations
    opacity: 0;
  }

  @media only screen and (max-width: 640px) {
    width: 100%;
  }
`

const Card = styled.div`
  width: 600px;
  height: 929px;

  width: 31.25vw;
  height: auto;

  border: 13px solid #ffffff;
  border-radius: 60px;

  margin: 0 44px;
  margin: 0 2.292vw;

  padding-top: 26px;
  padding-bottom: 38px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  text-align: center;

  h4 {
    margin-bottom: 9px;
  }

  p {
    width: 70%;
    margin-bottom: 33px;
  }

  @media only screen and (max-width: 1440px) {
  }

  @media only screen and (max-width: 1194px) {
    border-width: 7px;
    border-radius: 46px;

    width: 377px;
    height: 623px;
    height: auto;

    margin: 0 13.5px;

    padding: 18px;
    padding-bottom: 37px;

    p {
      width: 85%;
      margin-bottom: 20px;
    }
  }

  @media only screen and (max-width: 834px) {
    width: 377px;

    margin: 0 auto;
  }

  @media only screen and (max-width: 640px) {
    margin: 0 auto;
    width: 90vw;
  }
`

const GreenCard = styled(Card)`
  background-color: #d4f8d0;

  @media only screen and (min-width: 835px) {
    // to offset animations
    opacity: 0;
    -webkit-transform: translate(-5px, 5px);
    transform: translate(-5px, 5px);
  }
`

const BlueCard = styled(Card)`
  background-color: #d2faf9;

  @media only screen and (min-width: 835px) {
    // to offset animations
    opacity: 0;
    -webkit-transform: translate(5px, 5px);
    transform: translate(5px, 5px);
  }
`

const ImgContainer = styled.div`
  // border: 1px solid hotpink;
  width: 100%;
  min-height: 250px;

  display: flex;
  justify-content: center;
  align-items: center;

  @media only screen and (max-width: 1440px) {
    min-height: 150px;
  }
`

const WebsiteImgWrapper = styled.div`
  // border: 1px solid hotpink;
  width: 250px;
  height: 250px;

  background-image: url(${WebsiteImg});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;

  @media only screen and (max-width: 1440px) {
    width: 150px;
    height: 150px;
  }
`

const ShopifyImgWrapper = styled.div`
  // border: 1px solid hotpink;
  width: 210px;
  height: 220px;

  background-image: url(${ShopifyImg});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;

  @media only screen and (max-width: 1440px) {
    width: 130px;
    height: 130px;
  }
`

const List = styled.ul`
  // border: 1px solid hotpink;

  list-style-type: none;
  margin-bottom: 20px;

  @media only screen and (max-width: 1194px) {
    margin-bottom: 32px;
  }
`

const Item = styled.li`
  // border: 1px solid hotpink;
  display: flex;
  align-items: center;

  text-align: left;

  @media only screen and (max-width: 640px) {
    align-items: flex-start;
  }
`

const IconWrapper = styled.div`
  // border: 1px solid hotpink;

  // to set checkmark icon size
  font-size: 30px;

  display: inline-block;
  margin-right: 11px;

  @media only screen and (max-width: 1440px) {
    font-size: 20px;
    margin-right: 9px;
  }
`

function NextArrow(props) {
  const { className, style, onClick } = props
  return (
    <div
      className="slick-arrow"
      style={{
        // border: "1px solid hotpink",
        // backgroundColor: "white",
        width: "50px",
        height: "50px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "50%",
        // border: "1px solid hotpink",
        fontSize: "32px",
        position: "absolute",
        top: "50%",
        right: "-50px",
        // right: "-44px",
        bottom: "auto",
        transform: "translate(-50%,-50%)",
        zIndex: "1",
      }}
      onClick={onClick}
    >
      <FontAwesomeIcon icon={faCaretRight} color="#54575A" />
    </div>
  )
}

function PrevArrow(props) {
  const { className, style, onClick } = props
  return (
    <div
      className="slick-arrow"
      style={{
        // backgroundColor: "white",
        width: "50px",
        height: "50px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "50%",
        // border: "1px solid hotpink",
        fontSize: "32px",
        position: "absolute",
        top: "50%",
        bottom: "auto",
        transform: "translate(-50%,-50%)",
        zIndex: "1",
      }}
      onClick={onClick}
    >
      <FontAwesomeIcon icon={faCaretLeft} color="#54575A" />
    </div>
  )
}

function MobileNextArrow(props) {
  const { className, style, onClick } = props
  return (
    <div
      className="slick-arrow"
      style={{
        // border: "1px solid hotpink",
        // backgroundColor: "white",
        width: "50px",
        height: "50px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "50%",
        // border: "1px solid hotpink",
        fontSize: "32px",
        position: "absolute",
        top: "50%",
        // right: "-50px",
        right: "-20px",
        right: "-44px",
        bottom: "auto",
        transform: "translate(-50%,-50%)",
        zIndex: "1",
      }}
      onClick={onClick}
    >
      <FontAwesomeIcon icon={faCaretRight} color="#54575A" />
    </div>
  )
}

function MobilePrevArrow(props) {
  const { className, style, onClick } = props
  return (
    <div
      className="slick-arrow"
      style={{
        // backgroundColor: "white",
        width: "50px",
        height: "50px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "50%",
        // border: "1px solid hotpink",
        fontSize: "32px",
        position: "absolute",
        top: "50%",
        // left: "30px",
        left: "6px",
        bottom: "auto",
        transform: "translate(-50%,-50%)",
        zIndex: "1",
      }}
      onClick={onClick}
    >
      <FontAwesomeIcon icon={faCaretLeft} color="#54575A" />
    </div>
  )
}

const SliderSettings = {
  arrows: true,
  infinite: true,
  speed: 500,
  // autoplay: true,
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
  responsive: [
    {
      breakpoint: 640,
      settings: {
        nextArrow: <MobileNextArrow />,
        prevArrow: <MobilePrevArrow />,
      },
    },
  ],
}

const Services = () => {
  const [ref, inView] = useInView({ threshold: 0.15 })

  return (
    <Container ref={ref} className={inView ? "animate" : null}>
      <div className="anchor-offset" id="services" />
      <YellowBubble className="yellow-bubble">
        <Heading>How I Can Help</Heading>
      </YellowBubble>
      <PurpleBubble className="purple-bubble">
        <span>I build:</span>
      </PurpleBubble>

      <CardContainer>
        <GreenCard className="green-card">
          <h3>Websites</h3>
          <ImgContainer>
            <WebsiteImgWrapper></WebsiteImgWrapper>
          </ImgContainer>

          <h4>
            <span className="pink">Completely custom</span> web experiences,
            <br></br>coded from scratch
          </h4>
          <p>
            I’ll help you create an unmatched web experience that tells an
            unforgettable brand story to your dream customers.
            <br></br>
            <br></br>Your website will be carefully crafted using the most
            modern web technologies, guaranteeing the highest quality and
            performance.
          </p>

          <List>
            <Item>
              <IconWrapper>
                <FontAwesomeIcon icon={faCheckCircle} color="white" />
              </IconWrapper>
              <span>No templates, no limits - anything is possible!</span>
            </Item>
            <Item>
              <IconWrapper>
                <FontAwesomeIcon icon={faCheckCircle} color="white" />
              </IconWrapper>
              <span>Incredible representation of your brand</span>
            </Item>
            <Item>
              <IconWrapper>
                <FontAwesomeIcon icon={faCheckCircle} color="white" />
              </IconWrapper>
              <span>
                Intuitive, striking and works 24/7 so you don’t have to
              </span>
            </Item>
          </List>

          <Link to="contact" spy={true} smooth={true}>
            <button>Chat with me!</button>
          </Link>
        </GreenCard>

        <BlueCard className="blue-card">
          <h3>Shopify Stores</h3>
          <ImgContainer>
            <ShopifyImgWrapper></ShopifyImgWrapper>
          </ImgContainer>
          <h4>
            <span className="pink">Immersive</span> shopping experiences,
            <br></br>
            built for conversions
          </h4>
          <p>
            I’ll help you create a memorable, easy-to-recognize brand experience
            that forms a genuine emotional connection with your consumers.
            <br></br>
            <br></br>Your shop will be thoughtfully designed with built-in
            strategies to optimize revenue and make shopping an absolute joy.
          </p>

          <List>
            <Item>
              <IconWrapper>
                <FontAwesomeIcon icon={faCheckCircle} color="white" />
              </IconWrapper>
              <span>Your very own customized theme to fit your brand</span>
            </Item>
            <Item>
              <IconWrapper>
                <FontAwesomeIcon icon={faCheckCircle} color="white" />
              </IconWrapper>
              <span>User-centered design for easy, seamless shopping</span>
            </Item>
            <Item>
              <IconWrapper>
                <FontAwesomeIcon icon={faCheckCircle} color="white" />
              </IconWrapper>
              <span>Designed to transform visitors into brand advocates</span>
            </Item>
          </List>

          <Link to="contact" spy={true} smooth={true}>
            <button>Chat with me!</button>
          </Link>
        </BlueCard>
      </CardContainer>

      <MobileCardContainer className="mobile-card-container">
        <Slider {...SliderSettings}>
          <div>
            <GreenCard className="green-card">
              <h3>Websites</h3>
              <ImgContainer>
                <WebsiteImgWrapper></WebsiteImgWrapper>
              </ImgContainer>

              <h4>
                <span className="pink">Completely custom</span> web experiences,
                <br></br>coded from scratch
              </h4>
              <p>
                I’ll help you create an unmatched web experience that tells an
                unforgettable brand story to your dream customers.
                <br></br>
                <br></br>Your website will be carefully crafted using the most
                modern web technologies, guaranteeing the highest quality and
                performance.
              </p>

              <List>
                <Item>
                  <IconWrapper>
                    <FontAwesomeIcon icon={faCheckCircle} color="white" />
                  </IconWrapper>
                  <span>No templates, no limits - anything is possible!</span>
                </Item>
                <Item>
                  <IconWrapper>
                    <FontAwesomeIcon icon={faCheckCircle} color="white" />
                  </IconWrapper>
                  <span>Incredible representation of your brand</span>
                </Item>
                <Item>
                  <IconWrapper>
                    <FontAwesomeIcon icon={faCheckCircle} color="white" />
                  </IconWrapper>
                  <span>
                    Intuitive, striking and works 24/7 so you don’t have to
                  </span>
                </Item>
              </List>

              <Link to="contact" spy={true} smooth={true}>
                <button>Chat with me!</button>
              </Link>
            </GreenCard>
          </div>

          <div>
            <BlueCard className="blue-card">
              <h3>Shopify Stores</h3>
              <ImgContainer>
                <ShopifyImgWrapper></ShopifyImgWrapper>
              </ImgContainer>
              <h4>
                <span className="pink">Immersive</span> shopping experiences,
                <br></br>
                built for conversions
              </h4>
              <p>
                I’ll help you create a memorable, easy-to-recognize brand
                experience that forms a genuine emotional connection with your
                consumers.
                <br></br>
                <br></br>Your shop will be thoughtfully designed with built-in
                strategies to optimize revenue and make shopping an absolute
                joy.
              </p>

              <List>
                <Item>
                  <IconWrapper>
                    <FontAwesomeIcon icon={faCheckCircle} color="white" />
                  </IconWrapper>
                  <span>Your very own customized theme to fit your brand</span>
                </Item>
                <Item>
                  <IconWrapper>
                    <FontAwesomeIcon icon={faCheckCircle} color="white" />
                  </IconWrapper>
                  <span>User-centered design for easy, seamless shopping</span>
                </Item>
                <Item>
                  <IconWrapper>
                    <FontAwesomeIcon icon={faCheckCircle} color="white" />
                  </IconWrapper>
                  <span>
                    Designed to transform visitors into repeat customers
                  </span>
                </Item>
              </List>

              <Link to="contact" spy={true} smooth={true}>
                <button>Chat with me!</button>
              </Link>
            </BlueCard>
          </div>
        </Slider>
      </MobileCardContainer>
    </Container>
  )
}

export default Services
