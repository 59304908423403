import React from "react"
import styled, { css } from "styled-components"
import NumberFormat from "react-number-format"

import DownArrow from "../images/contact-form/down-arrow.svg"

const InputCss = css`
  border: 1px solid #54575a;
  border-radius: 40px;
  background-colour: #ffffff;
  opacity: 0.67;
  outline: none;

  width: 290px;
  height: 50px;

  font-size: 20px;
  line-height: 1.85;

  padding: 0 1.1em;

  margin: 0 9px;
  margin-bottom: 13px;

  &.err {
    border: 2px solid red;
  }

  &.width-100 {
    width: calc(100% - 18px);
  }

  &:focus-within {
    transition: all 0.3s ease-in-out;
    border: 1px solid #d51280;
    // border: 1px solid #fedcda;
  }

  @media only screen and (max-width: 1194px) {
    width: 225px;
  }

  @media only screen and (max-width: 640px) {
    margin-left: 0;
    margin-right: 0;

    width: 100%;

    &.width-100 {
      width: 100%;
    }
  }
`

export const ErrorMsg = styled.p`
  // border: 1px solid red;
  color: red;
  font-weight: 600;
  text-align: center;

  width: 290px;
  margin-top: -7px;
  margin-bottom: 15px;
  margin-left: 9px;

  &.width-100 {
    width: calc(100% - 18px);
  }

  &.left {
    text-align: left;
    margin-left: 20px;
  }

  @media only screen and (max-width: 1194px) {
    width: 225px;
  }

  @media only screen and (max-width: 640px) {
    margin-left: 0;

    width: 100%;

    &.left {
      margin-left: 11px;
    }
  }
`

export const Button = styled.input`
  margin: 10px 9px 0 9px;

  background-color: #d51280;
  border-radius: 40px;
  width: 286px;
  height: 70px;
  color: white;
  border: none;
  outline: none;
  cursor: pointer;

  font-weight: 700;
  font-size: 22px;
  text-transform: uppercase;
  letter-spacing: 2.93px;
  line-height: 1.4;

  transition: all 0.3s ease;

  &:hover {
    color: #d51280;
    background-color: #fff1f0;
  }

  @media screen and (max-width: 1440px) {
    width: 200px;
    height: 50px;
    font-size: 18px;
    letter-spacing: 2px;
  }

  @media only screen and (max-width: 640px) {
    margin-left: 0;
    margin-right: 0;

    width: 45%;
  }
`
export const Input = styled.input`
  ${InputCss}
`
export const Select = styled.select`
  ${InputCss}

  appearance: none:
  -moz-appearance: none;
  -webkit-appearance: none;

  background: url(${DownArrow}) no-repeat right 1rem center #fff;
  background-size: 12px 12px;
`

export const StyledNumberFormat = styled(NumberFormat)`
  ${InputCss}
`

const TextCss = css`
  // border: 1px solid pink;
  font-size: 20px;
  font-weight: 600;
  display: inline-block;

  margin: 0 9px;
  margin-bottom: 13px;

  @media only screen and (max-width: 640px) {
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 0;
  }
`

export const QuestionText = styled.p`
  ${TextCss}
`

export const QuestionLabel = styled.label`
  ${TextCss}
  font-weight: 400;

  position: absolute;
  top: 10px;
  left: 0.8em;
  opacity: 0.67;
  z-index: 1;
`

export const RadioGroupWrapper = styled.div`
  // border: 1px solid lightyellow;
  display: inline;

  div {
    display: inline;
  }

  &.width-100 {
    display: block;
    margin-top: -9px;
    margin-left: 9px;
    margin-bottom: 13px;
  }

  @media only screen and (max-width: 640px) {
    display: flex;
    margin-bottom: 13px;

    display: block;

    div {
      display: block;
    }

    &.width-100 {
      margin-left: 0;
      margin-top: 0;
    }
  }
`

export const RadioWrapper = styled.div`
  // border: 1px solid lightblue;
  display: inline;

  @media only screen and (max-width: 640px) {
    margin-left: 15px;
    margin-bottom: 5px;
  }
`

export const RadioLabel = styled.label`
  // border: 1px solid pink;
  font-size: 20px;
  margin-left: 5px;
  margin-right: 15px;

  @media only screen and (max-width: 640px) {
    margin-left: 10px;
  }
`

export const TextArea = styled.textarea`
  border: 1px solid #54575a;
  border-radius: 20px;
  background-colour: #ffffff;
  opacity: 0.67;
  outline: none;
  width: calc(100% - 18px);

  font-size: 20px;
  line-height: 1.85;

  padding: 0.3em 0.8em;
  // offset question label
  padding-top: 1.8em;
  margin: 0 9px;
  margin-bottom: 13px;

  resize: none;

  &.err {
    border: 2px solid red;
  }

  &:focus-within {
    transition: all 0.3s ease-in-out;
    border: 1px solid #d51280;
    // border: 1px solid #fedcda;
  }

  @media only screen and (max-width: 1194px) {
  }

  @media only screen and (max-width: 640px) {
    margin-left: 0;
    margin-right: 0;

    width: 100%;

    // offset question label
    padding-top: 3.2em;
  }
`

export const DateInput = styled.input`
  ${InputCss}

  width: auto;

  &:before {
    content: attr(placeholder);
  }
`

export const CheckboxGroupWrapper = styled(RadioGroupWrapper)`
  // border: 1px solid pink;

  div {
    display: block;
  }
`

export const CheckboxWrapper = styled(RadioWrapper)`
  // border: 1px solid lightyellow;
  margin-bottom: 5px;
`

export const CheckboxLabel = styled(RadioLabel)`
  // border: 1px solid lightblue;
  margin-left: 10px;
`

export default Input
