import { css } from "styled-components"

const fadeIn = css`
  @-webkit-keyframes fadeIn {
    0% {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }
`

const raiseIn = css`
  @-webkit-keyframes raiseIn {
    0% {
      -webkit-transform: translateY(5px);
      transform: translateY(5px);
    }

    to {
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }
  }

  @keyframes raiseIn {
    0% {
      -webkit-transform: translateY(5px);
      transform: translateY(5px);
    }

    to {
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }
  }
`

const leftIn = css`
  @-webkit-keyframes leftIn {
    0% {
      -webkit-transform: translateX(-5px);
      transform: translateX(-5px);
    }

    to {
      -webkit-transform: translateX(0);
      transform: translateX(0);
    }
  }

  @keyframes leftIn {
    0% {
      -webkit-transform: translateX(-5px);
      transform: translateX(-5px);
    }

    to {
      -webkit-transform: translateX(0);
      transform: translateX(0);
    }
  }
`

const rightIn = css`
  @-webkit-keyframes rightIn {
    0% {
      -webkit-transform: translateX(5px);
      transform: translateX(5px);
    }

    to {
      -webkit-transform: translateX(0);
      transform: translateX(0);
    }
  }

  @keyframes rightIn {
    0% {
      -webkit-transform: translateX(5px);
      transform: translateX(5px);
    }

    to {
      -webkit-transform: translateX(0);
      transform: translateX(0);
    }
  }
`

const dropOut = css`
  @-webkit-keyframes dropOut {
    0% {
      -webkit-transform: translateY(-5px);
      transform: translateY(-5px);
    }

    to {
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }
  }

  @keyframes dropOut {
    0% {
      -webkit-transform: translateY(-5px);
      transform: translateY(-5px);
    }

    to {
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }
  }
`

const zoomIn = css`
  @-webkit-keyframes zoomIn {
    0% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }

    to {
      -webkit-transform: scale(1.2);
      transform: scale(1.2);
    }
  }

  @keyframes zoomIn {
    0% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }

    to {
      -webkit-transform: scale(1.2);
      transform: scale(1.2);
    }
  }
`

const bubbleZoomIn = css`
  @keyframes bubbleZoomIn {
    0% {
      transform: scale(0.8);
    }

    100% {
      transform: scale(1);
    }
  }
`

const leftRaiseIn = css`
  @-webkit-keyframes leftRaiseIn {
    0% {
      -webkit-transform: translate(-5px, 5px);
      transform: translate(-5px, 5px);
    }

    to {
      -webkit-transform: translate(0, 0);
      transform: translate(0, 0);
    }
  }

  @keyframes leftRaiseIn {
    0% {
      -webkit-transform: translate(-5px, 5px);
      transform: translate(-5px, 5px);
    }

    to {
      -webkit-transform: translate(0, 0);
      transform: translate(0, 0);
    }
  }
`

const rightRaiseIn = css`
  @-webkit-keyframes rightRaiseIn {
    0% {
      -webkit-transform: translate(5px, 5px);
      transform: translate(5px, 5px);
    }

    to {
      -webkit-transform: translate(0, 0);
      transform: translate(0, 0);
    }
  }

  @keyframes rightRaiseIn {
    0% {
      -webkit-transform: translate(5px, 5px);
      transform: translate(5px, 5px);
    }

    to {
      -webkit-transform: translate(0, 0);
      transform: translate(0, 0);
    }
  }
`

const leftOut = css`
  @-webkit-keyframes leftOut {
    0% {
      -webkit-transform: translateX(10px);
      transform: translateX(10px);
    }

    to {
      -webkit-transform: translateX(0);
      transform: translateX(0);
    }
  }

  @keyframes leftOut {
    0% {
      -webkit-transform: translateX(10px);
      transform: translateX(10px);
    }

    to {
      -webkit-transform: translateX(0);
      transform: translateX(0);
    }
  }
`

const rightOut = css`
  @-webkit-keyframes rightOut {
    0% {
      -webkit-transform: translateX(-10px);
      transform: translateX(-10px);
    }

    to {
      -webkit-transform: translateX(0);
      transform: translateX(0);
    }
  }

  @keyframes rightOut {
    0% {
      -webkit-transform: translateX(-10px);
      transform: translateX(-10px);
    }

    to {
      -webkit-transform: translateX(0);
      transform: translateX(0);
    }
  }
`

const scrollingText = css`
  @-webkit-keyframes scrollingText {
    from {
      -webkit-transform: translateY(100%);
      transform: translateY(100%);
    }

    to {
      -webkit-transform: translateY(-100%);
      transform: translateY(-100%);
    }
  }

  @keyframes scrollingText {
    from {
      -webkit-transform: translateY(100%);
      transform: translateY(100%);
    }

    to {
      -webkit-transform: translateY(-100%);
      transform: translateY(-100%);
    }
  }
`

const firstInStyles = css`
  opacity: 0;
  ${fadeIn}
  ${raiseIn}
  -webkit-animation: fadeIn 0.7s ease-in 0s forwards,
    raiseIn 0.7s ease-in 0s forwards;
  animation: fadeIn 0.7s ease-in 0s forwards, raiseIn 0.7s ease-in 0s forwards;
`

const firstRightInStyles = css`
  opacity: 0;
  ${fadeIn}
  ${rightIn}
  -webkit-animation: fadeIn 0.7s ease-in 0.3s forwards,
    rightIn 0.7s ease-in .3s forwards;
  animation: fadeIn 0.7s ease-in 0.3s forwards,
    rightIn 0.7s ease-in 0.3s forwards;
`

const firstLeftInStyles = css`
  opacity: 0;
  ${fadeIn}
  ${leftIn}
  -webkit-animation: fadeIn 0.7s ease-in 0.3s forwards,
    leftIn 0.7s ease-in .3s forwards;
  animation: fadeIn 0.7s ease-in 0.3s forwards,
    leftIn 0.7s ease-in 0.3s forwards;
`

const secondInStyles = css`
  opacity: 0;
  ${fadeIn}
  ${raiseIn}
  -webkit-animation: fadeIn 0.7s ease-in 0.3s forwards,
    raiseIn 0.7s ease-in 0.3s forwards;
  animation: fadeIn 0.7s ease-in 0.3s forwards,
    raiseIn 0.7s ease-in 0.3s forwards;
`

const thirdInStyles = css`
  opacity: 0;
  ${fadeIn}
  ${raiseIn}
  -webkit-animation: fadeIn 0.7s ease-in 0.7s forwards,
    raiseIn 0.7s ease-in 0.7s forwards;
  animation: fadeIn 0.7s ease-in 0.7s forwards,
    raiseIn 0.7s ease-in 0.7s forwards;
`

const fourthInStyles = css`
  opacity: 0;
  ${fadeIn}
  ${raiseIn}
  -webkit-animation: fadeIn 0.7s ease-in 1s forwards,
    raiseIn 0.7s ease-in 1s forwards;
  animation: fadeIn 0.7s ease-in 1s forwards, raiseIn 0.7s ease-in 1s forwards;
`

export {
  fadeIn,
  raiseIn,
  leftIn,
  rightIn,
  leftOut,
  rightOut,
  zoomIn,
  bubbleZoomIn,
  dropOut,
  scrollingText,
  firstInStyles,
  secondInStyles,
  thirdInStyles,
  fourthInStyles,
  firstRightInStyles,
  firstLeftInStyles,
  leftRaiseIn,
  rightRaiseIn,
}
