import * as React from "react"
import styled from "styled-components"
import { Link } from "react-scroll"

import Slider from "react-slick"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCaretRight, faCaretLeft } from "@fortawesome/free-solid-svg-icons"

import { useInView } from "react-intersection-observer"

import {
  fadeIn,
  leftOut,
  rightOut,
  dropOut,
  firstInStyles,
  secondInStyles,
  thirdInStyles,
  fourthInStyles,
} from "./animations"

const Container = styled.div`
  // border: 1px solid hotpink;
  width: 100%;
  height: 100%;

  margin-top: 15vh;
  margin-bottom: 10.83vh;

  display: flex;
  flex-direction: column;
  align-items: center;

  &.animate {
    button {
      opacity: 0;
      ${fadeIn}
      ${dropOut}
      -webkit-animation: fadeIn 0.7s ease-in 0.3s forwards,
        dropOut 0.7s ease-in 0.3s forwards;
      animation: fadeIn 0.7s ease-in 0.3s forwards,
        dropOut 0.7s ease-in 0.3s forwards;
    }

    @media only screen and (max-width: 834px) {
      button {
        ${fourthInStyles}
      }
    }
  }
`

const HeadingContainer = styled.div`
  // border: 1px solid hotpink;
  width: 23%;
  height: 100%;

  text-align: center;

  display: flex;
  flex-direction: column;
  align-items: center;

  margin-bottom: 57px;

  @media only screen and (max-width: 1194px) {
    width: 30%;

    margin-bottom: 43px;
  }

  @media only screen and (max-width: 834px) {
    width: 40%;
  }

  @media only screen and (max-width: 640px) {
    width: 90%;

    margin-bottom: 30px;
  }

  // to offset animations
  h2,
  p {
    opacity: 0;
  }

  &.animate {
    h2 {
      ${firstInStyles}
    }

    p {
      ${secondInStyles}
    }
  }
`

const Heading = styled.h2`
  // border: 1px solid hotpink;

  &:after {
    display: block;
    content: "";
    width: 8.2em;
    height: 0.83em;
    background-color: white;
    position: relative;
    top: -0.65em;
    left: 0.52em;
    z-index: -1;
  }

  @media only screen and (max-width: 1194px) {
    &:after {
      margin-bottom: 5px;
    }
  }
`

const ContentContainer = styled.div`
  // border: 1px solid hotpink;
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;

  margin-bottom: 84px;

  @media only screen and (max-width: 1194px) {
    margin-bottom: 65px;
  }

  @media only screen and (max-width: 834px) {
    display: none;
  }

  &.animate {
    .blue-bubble {
      opacity: 0;
      ${fadeIn}
      ${leftOut}

      -webkit-animation: fadeIn 0.7s ease-in 0.3s forwards,
      leftOut 0.7s ease-in 0.3s forwards;

      animation: fadeIn 0.7s ease-in 0.3s forwards,
        leftOut 0.7s ease-in 0.3s forwards;
    }

    .yellow-bubble {
      opacity: 0;
      ${fadeIn}
      -webkit-animation: fadeIn 0.7s ease-in 0.3s forwards;
      animation: fadeIn 0.7s ease-in 0.3s forwards;
    }

    .green-bubble {
      opacity: 0;
      ${fadeIn}
      ${rightOut}

      -webkit-animation: fadeIn 0.7s ease-in 0.3s forwards,
      rightOut 0.7s ease-in 0.3s forwards;

      animation: fadeIn 0.7s ease-in 0.3s forwards,
        rightOut 0.7s ease-in 0.3s forwards;
    }
  }
`

const MobileContentContainer = styled.div`
  display: none;

  @media only screen and (max-width: 834px) {
    // border: 1px solid blue;
    display: block;
    width: 100%;
    width: 60%;
    height: 100%;

    margin-bottom: 48px;

    &.animate {
      ${thirdInStyles}
    }
  }

  @media only screen and (max-width: 640px) {
    width: 88%;
  }
`

const Bubble = styled.div`
  width: 411px;
  height: 411px;

  border: 15px solid #ffffff;
  border-radius: 50%;

  margin: 0 46px;

  text-align: center;

  @media only screen and (max-width: 1440px) {
    width: 325px;
    height: 325px;
  }

  @media only screen and (max-width: 1194px) {
    width: 300px;
    height: 300px;
    border: 7px solid #ffffff;

    margin: 0 17px;
  }

  @media only screen and (max-width: 834px) {
    margin: 0 auto;
  }
`

const YellowBubble = styled(Bubble)`
  background: linear-gradient(180deg, white 50%, #f8e340 50%);
`

const BlueBubble = styled(Bubble)`
  background: linear-gradient(180deg, white 50%, #d2faf9 50%);
`

const GreenBubble = styled(Bubble)`
  background: linear-gradient(180deg, white 50%, #d4f8d0 50%);
`

const Emoji = styled.div`
  // border: 1px solid hotpink;
  font-size: 72px;
  font-size: 3.75vw;
  line-height: 1;

  margin-top: 50px;
  margin-top: 2.6042vw;
  margin-bottom: 9px;

  @media only screen and (max-width: 1194px) {
    font-size: 42px;

    margin-top: 47.5px;
  }
`

const SubHeading = styled.h4`
  // border: 1px solid hotpink;
  font-size: 30px;
  font-size: 1.5625vw;
  font-weight: 500;
  line-height: 1.53;

  margin-bottom: 27px;

  @media only screen and (max-width: 1440px) {
    margin-bottom: 3.2vh;
  }

  @media only screen and (max-width: 1194px) {
    font-size: 21px;
    line-height: 31px;

    margin-bottom: 20px;
  }
`

const Description = styled.p`
  // border: 1px solid hotpink;
  line-height: 1.5;

  @media only screen and (max-width: 1194px) {
    line-height: 1.4;
  }
`

function NextArrow(props) {
  const { className, style, onClick } = props
  return (
    <div
      className="slick-arrow"
      style={{
        // border: "1px solid hotpink",
        // backgroundColor: "white",
        width: "50px",
        height: "50px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "50%",
        // border: "1px solid hotpink",
        fontSize: "32px",
        position: "absolute",
        top: "50%",
        right: "-50px",
        // right: "-44px",
        bottom: "auto",
        transform: "translate(-50%,-50%)",
        zIndex: "1",
      }}
      onClick={onClick}
    >
      <FontAwesomeIcon icon={faCaretRight} color="#54575A" />
    </div>
  )
}

function PrevArrow(props) {
  const { className, style, onClick } = props
  return (
    <div
      className="slick-arrow"
      style={{
        // backgroundColor: "white",
        width: "50px",
        height: "50px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "50%",
        // border: "1px solid hotpink",
        fontSize: "32px",
        position: "absolute",
        top: "50%",
        bottom: "auto",
        transform: "translate(-50%,-50%)",
        zIndex: "1",
      }}
      onClick={onClick}
    >
      <FontAwesomeIcon icon={faCaretLeft} color="#54575A" />
    </div>
  )
}

const SliderSettings = {
  arrows: true,
  infinite: true,
  speed: 500,
  initialSlide: 1,
  // autoplay: true,
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
  responsive: [
    {
      breakpoint: 640,
      settings: {
        // nextArrow: <MobileNextArrow />,
        // prevArrow: <MobilePrevArrow />,
      },
    },
  ],
}

const WhyCCShop = () => {
  const [ref, inView] = useInView({ threshold: 0.15 })

  return (
    <Container ref={ref} className={inView ? "animate" : null}>
      <div className="anchor-offset" id="whyccshop" />
      <HeadingContainer className={inView ? "animate" : null}>
        <Heading>Why The CC Shop?</Heading>
        <p style={{ marginTop: "-1rem" }}>
          First of all, I’m a single human bean &#x2615; dedicated to yourself
          and your business - no handoffs,<br></br>no confusion.
        </p>
      </HeadingContainer>

      <ContentContainer className={inView ? "animate" : null}>
        <BlueBubble className="blue-bubble">
          <Emoji>&#x1F525;</Emoji>
          <SubHeading>The Passion is Real</SubHeading>
          <Description>
            I love people who are doing{" "}
            <span
              style={{
                fontStyle: "italic",
              }}
            >
              their thing.
            </span>
            <br></br>I want to know your story and share that
            <br></br>passion with you! So you can trust
            <br></br>that I’ll be just as invested
            <br></br>as you are.
          </Description>
        </BlueBubble>
        <YellowBubble className="yellow-bubble">
          <Emoji>&#x1F9D0;</Emoji>
          <SubHeading>Technical Expertise</SubHeading>
          <Description>
            I’ve coded backends that support 1M users.
            <br></br>Now I use the most modern front end
            <br></br>web technologies to build sites that
            <br></br>are completely customizable,
            <br></br>reliable and fast.
          </Description>
        </YellowBubble>
        <GreenBubble className="green-bubble">
          <Emoji>&#x1F336;</Emoji>
          <SubHeading>Professional but Spicy</SubHeading>
          <Description>
            I get it done and I get it done right. I’m
            <br></br>also friendly, bubbly and I love to
            <br></br>laugh and connect! We’ll build
            <br></br>your dream and have
            <br></br>fun doing it too.
          </Description>
        </GreenBubble>
      </ContentContainer>

      <MobileContentContainer className={inView ? "animate" : null}>
        <Slider {...SliderSettings}>
          <div>
            <BlueBubble>
              <Emoji>&#x1F525;</Emoji>
              <SubHeading>The Passion is Real</SubHeading>
              <Description>
                I love people who are doing{" "}
                <span
                  style={{
                    fontStyle: "italic",
                  }}
                >
                  their thing.
                </span>
                <br></br>I want to know your story and share that
                <br></br>passion with you! So you can trust
                <br></br>that I’ll be just as invested
                <br></br>as you are.
              </Description>
            </BlueBubble>
          </div>
          <div>
            <YellowBubble>
              <Emoji>&#x1F9D0;</Emoji>
              <SubHeading>Technical Expertise</SubHeading>
              <Description>
                I’ve coded backends that support 1M users.
                <br></br>Now I use the most modern front end
                <br></br>web technologies to build sites that
                <br></br>are completely customizable,
                <br></br>reliable and fast!
              </Description>
            </YellowBubble>
          </div>
          <div>
            <GreenBubble>
              <Emoji>&#x1F336;</Emoji>
              <SubHeading>Professional but Spicy</SubHeading>
              <Description>
                I get it done and I get it done right. I’m
                <br></br>also friendly, bubbly and I love to
                <br></br>laugh and connect! We’ll build
                <br></br>your dream and have
                <br></br>fun doing it too.
              </Description>
            </GreenBubble>
          </div>
        </Slider>
      </MobileContentContainer>
      <Link to="contact" spy={true} smooth={true}>
        <button className="pink-bkg">Ask me more!</button>
      </Link>
    </Container>
  )
}

export default WhyCCShop
