import { css } from "styled-components"

const float = css`
  @keyframes float {
    0% {
      transform: translate3d(0, 0px, 0);
    }

    50% {
      transform: translate3d(0, -15px, 0);
    }

    100% {
      transform: translate3d(0, 0px, 0);
    }
  }
`

const bounceTwo = css`
  @keyframes bounceTwo {
    // 0%,
    // 20%,
    // 50%,
    // 80%,
    // 100% {
    //   transform: translateY(0);
    // }
    // 40% {
    //   transform: translateY(-20px);
    // }
    // 60% {
    //   transform: translateY(-10px);
    // }

    0%,
    20%,
    40%,
    60%,
    100% {
      transform: translateY(0);
    }
    30% {
      transform: translateY(-20px);
    }
    50% {
      transform: translateY(-10px);
    }
  }
`

const zoomIn = css`
  @keyframes zoomIn {
    0% {
      transform: scale(0.8);
    }

    100% {
      transform: scale(1);
    }
  }
`

const rotateInDownLeft = css`
  @keyframes rotateInDownLeft {
    0% {
      transform-origin: left bottom;
      transform: rotate(-90deg);
      opacity: 0;
    }
    100% {
      transform-origin: left bottom;
      transform: rotate(0);
      opacity: 1;
    }
  }
`

const rotateInUpLeft = css`
  @keyframes rotateInUpLeft {
    0% {
      transform-origin: left bottom;
      transform: rotate(90deg);
      opacity: 0;
    }
    100% {
      transform-origin: left bottom;
      transform: rotate(0);
      opacity: 1;
    }
  }
`

const confettiShooter = css`
  @keyframes confettiShooter {
    0% {
      transform: rotate(-45deg) translateY(0);
    }

    50% {
      transform: rotate(-45deg) translateY(-10px);
    }

    100% {
      transform: rotate(-45deg) translateY(0);
    }
  }
`

const swing = css`
  @keyframes swing {
    20% {
      transform: rotate(10deg);
    }
    40% {
      transform: rotate(-5deg);
    }
    60% {
      transform: rotate(2deg);
    }
    80% {
      transform: rotate(-2deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
`

const wiggle = css`
  @keyframes wiggle {
    0%,
    12%,
    100% {
      transform: rotate(0deg);
    }

    3% {
      transform: rotate(5deg);
    }

    6% {
      transform: rotate(0deg);
    }

    9% {
      transform: rotate(5deg);
    }
  }
`

export {
  float,
  bounceTwo,
  zoomIn,
  rotateInDownLeft,
  rotateInUpLeft,
  confettiShooter,
  swing,
  wiggle,
}
