import * as React from "react"
import styled from "styled-components"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faInstagramSquare } from "@fortawesome/free-brands-svg-icons"

import { useInView } from "react-intersection-observer"

import {
  firstInStyles,
  secondInStyles,
  thirdInStyles,
  bubbleZoomIn,
} from "./animations"

import ContactFormMultiStep from "./ContactFormMultiStep.js"

const Container = styled.div`
  // border: 1px solid hotpink;
  width: 100%;
  height: auto;

  position: relative;
  overflow: hidden;

  display: flex;
  flex-direction: column;
  align-items: center;

  @media only screen and (max-width: 640px) {
    padding: 0 5%;
  }

  &.animate {
    h2 {
      ${firstInStyles}
    }

    h2 ~ p {
      ${secondInStyles}
    }

    .form-desc {
      ${thirdInStyles}
    }

    .form-wrapper {
      ${thirdInStyles}
    }
  }
`

const GreenBubble = styled.div`
  border-radius: 50%;
  background-color: #d4f8d0;

  position: absolute;
  left: 270px;
  top: 3.7vh;
  left: 14.0625vw;

  width: 500px;
  height: 500px;

  width: 26.04vw;
  height: 26.04vw;

  z-index: -1;

  &.animate {
    ${bubbleZoomIn}
    animation: bubbleZoomIn 2s;
  }

  @media only screen and (max-width: 834px) {
    left: 111px;

    left: 10.84vw;
    top: 5.5vh;

    width: 270px;
    height: 270px;

    left: -135px;
  }
`
const YellowBubble = styled.div`
  border-radius: 50%;
  background-color: #f8e340;

  position: absolute;
  bottom: -404px;
  left: -778px;

  left: -40.52vw;
  bottom: -21.04vw;

  width: 1721px;
  height: 1018px;

  width: 89.635vw;
  height: 53.02vw;

  z-index: -4;

  &.animate {
    ${bubbleZoomIn}
    animation: bubbleZoomIn 2s;
  }

  @media only screen and (max-width: 640px) {
    left: -1044px;
    bottom: -555px;

    left: -252.2vw;
    bottom: -134.058vw;

    width: 1425px;
    height: 946px;
  }
`
const LightPinkLgBubble = styled.div`
  border-radius: 50%;
  background-color: #fff1f0;

  // border: 1px solid pink;
  position: absolute;
  left: 807px;
  bottom: -1100px;

  left: 42.03vw;
  bottom: -57.29vw;

  width: 2053px;
  height: 1952px;

  width: 106.927vw;
  height: 101.67vw;

  z-index: -3;

  &.animate {
    ${bubbleZoomIn}
    animation: bubbleZoomIn 2s;
  }

  @media only screen and (max-width: 640px) {
    left: 156px;
    bottom: -644px;

    left: 37.68vw;
    bottom: -155.55vw;

    width: 1414px;
    height: 1238px;
  }
`
const PinkBubble = styled.div`
  border-radius: 50%;
  background-color: #fedcda;

  position: absolute;
  left: 936px;
  bottom: -853px;

  left: 48.75vw;
  bottom: -44.427vw;

  width: 1923px;
  height: 1404px;

  width: 100.16vw;
  height: 73.125vw;

  z-index: -2;

  &.animate {
    ${bubbleZoomIn}
    animation: bubbleZoomIn 2s;
  }

  @media only screen and (max-width: 640px) {
    left: 200px;
    bottom: -506px;

    left: 48.3vw;
    bottom: -122.22vw;

    width: 1226px;
    height: 921px;
  }
`
const LightPinkSmBubble = styled.div`
  border-radius: 50%;
  background-color: #fff1f0;

  position: absolute;
  left: 936px;
  bottom: -710px;

  left: 48.75vw;
  bottom: -36.98vw;

  width: 821px;
  height: 792px;

  width: 42.76vw;
  height: 41.25vw;

  z-index: -1;

  &.animate {
    ${bubbleZoomIn}
    animation: bubbleZoomIn 2s;
  }

  @media only screen and (max-width: 640px) {
    left: 197px;
    bottom: -257px;

    left: 47.58vw;
    bottom: -62.08vw;

    width: 390px;
    height: 390px;
  }
`

const HeadingContainer = styled.div`
  // border: 1px solid hotpink;
  width: 100%;
  height: auto;

  text-align: center;

  display: flex;
  flex-direction: column;
  align-items: center;
`

const Heading = styled.h2`
  // border: 1px solid hotpink;

  &:after {
    display: block;
    content: "";
    width: 6.4em;
    height: 0.83em;
    background-color: white;
    position: relative;
    top: -0.65em;
    left: 0.52em;
    z-index: -1;
  }

  @media only screen and (max-width: 1194px) {
    &:after {
      margin-bottom: 5px;
    }
  }

  // to offset animations
  opacity: 0;
`

const HeadingDescription = styled.p`
  // border: 1px solid hotpink;

  font-size: 30px;
  line-height: 1.23;
  line-height: 1.35;
  font-size: 1.5625vw;

  margin-bottom: 1em;

  @media only screen and (max-width: 1194px) {
    font-size: 18px;
  }

  // to offset animations
  opacity: 0;
`

const DesktopOnlySpacing = styled.br`
  display: block;

  @media only screen and (max-width: 640px) {
    display: none;
  }
`

const FormDescription = styled.p`
  margin-bottom: 1.5rem;

  text-align: center;

  // to offset animations
  opacity: 0;
`

const Footer = styled.footer`
  // border: 1px solid lightblue;
  width: auto;
  height: auto;

  padding-right: 1.5rem;
  padding-bottom: 1rem;

  align-self: flex-end;

  text-align: right;

  @media only screen and (max-width: 640px) {
    padding-right: 0;
  }
`

const SocialLinks = styled.div`
  // border: 1px solid lightblue;
  margin-bottom: 8px;
`

const IconWrapper = styled.div`
  // border: 1px solid hotpink;

  // to set checkmark icon size
  font-size: 36px;
  line-height: 1;

  display: inline-block;

  @media only screen and (max-width: 640px) {
    font-size: 48px;
  }
`

const FooterDivider = styled.div`
  width: 100%;
  height: 1px;

  background-color: #707070;

  margin: 15px 0;

  @media only screen and (max-width: 640px) {
    margin: 10px 0;
  }
`

const ContactAndFooter = () => {
  const [ref, inView] = useInView({ threshold: 0.15 })

  return (
    <Container ref={ref} className={inView ? "animate" : null}>
      <div className="anchor-offset" id="contact" />
      <GreenBubble className={inView ? "animate" : null} />
      <YellowBubble className={inView ? "animate" : null} />
      <LightPinkLgBubble className={inView ? "animate" : null} />
      <PinkBubble className={inView ? "animate" : null} />
      <LightPinkSmBubble className={inView ? "animate" : null} />

      <HeadingContainer>
        <Heading>Chat With Me!</Heading>
        <HeadingDescription style={{ marginTop: "-1rem" }}>
          You’ve already made it this far, let me help you take the next step.
          <DesktopOnlySpacing></DesktopOnlySpacing>
          &nbsp;I’ve got you, and you’ve got this!
        </HeadingDescription>
      </HeadingContainer>

      <FormDescription className="form-desc">
        First, I’ll need some information! Please share as much as you’d like
        below.
      </FormDescription>

      <div
        className="form-wrapper"
        style={{ position: `relative`, marginBottom: `3rem`, opacity: `0` }}
      >
        <ContactFormMultiStep></ContactFormMultiStep>
      </div>

      <Footer>
        <SocialLinks>
          <IconWrapper>
            <a href="https://www.instagram.com/ccshop.io/" target="_blank">
              <FontAwesomeIcon icon={faInstagramSquare} color="#54575A" />
            </a>
          </IconWrapper>
        </SocialLinks>
        <p>
          <a href="mailto: info@ccshop.io" style={{ textTransform: `none` }}>
            info@ccshop.io
          </a>
        </p>
        <FooterDivider />
        <p>© 2021 The CC Shop | All rights reserved</p>
      </Footer>
    </Container>
  )
}

export default ContactAndFooter
